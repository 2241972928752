import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import GavelIcon from "@mui/icons-material/Gavel";
import { BaseModal } from "components";
import tolgee from "services/translation";
var InfoType;
(function (InfoType) {
    InfoType["modal"] = "modal";
    InfoType["tooltip"] = "tooltip";
})(InfoType || (InfoType = {}));
function TolgeeWrapper(_a) {
    var translateKey = _a.translateKey;
    var _b = useState(false), isModalOpen = _b[0], setModalOpen = _b[1];
    var t = useTranslate().t;
    var _c = useState(false), isCustom = _c[0], setIsCustom = _c[1];
    var _d = useState(null), infoType = _d[0], setInfoType = _d[1];
    var _e = useState(null), iconName = _e[0], setIconName = _e[1];
    var _f = useState(null), translatedText = _f[0], setTranslatedText = _f[1];
    var _g = useState(InfoIcon), IconComponent = _g[0], setIconComponent = _g[1];
    useEffect(function () {
        var _a;
        var iconMap = {
            WarningIcon: WarningIcon,
            HelpCenterIcon: HelpCenterIcon,
            GavelIcon: GavelIcon,
            InfoIcon: InfoIcon,
        };
        var translatedKey = t(translateKey);
        var custom = translatedKey.startsWith("[#");
        if (custom) {
            // Custom translation example: [#type=modal#icon=InfoIcon#] Test Test Test
            setIsCustom(true);
            var content = (_a = translatedKey.match(/\[#(.+?)#\]/)) === null || _a === void 0 ? void 0 : _a[1];
            var result = content
                ? content.split("#").reduce(function (acc, pair) {
                    var _a = pair.split("="), key = _a[0], value = _a[1];
                    if (key && value) {
                        acc[key] = value;
                    }
                    return acc;
                }, {})
                : {};
            setInfoType(result.type || null);
            setIconName(result.icon || "Info");
            setTranslatedText(translatedKey.split("#]")[1].trim() || null);
            setIconComponent(iconMap[iconName] || InfoIcon);
        }
        else {
            setIsCustom(false);
        }
    }, [translateKey, iconName, t]);
    var handleInfoClick = function (event) {
        event.stopPropagation();
        event.preventDefault();
        if (infoType === InfoType.modal) {
            setModalOpen(true);
        }
    };
    function renderModalContent() {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-row items-center gap-2", children: [translatedText, _jsx(IconComponent, { className: "mr-2 cursor-pointer", onClick: function (e) { return handleInfoClick(e); } })] }), _jsx(BaseModal, { modalState: [isModalOpen, setModalOpen], children: _jsx(_Fragment, { children: tolgee.t("".concat(translateKey, ".infoContent")) }) })] }));
    }
    function renderTooltipContent() {
        return (_jsx(Tooltip, { title: tolgee.t("".concat(translateKey, ".infoContent")), arrow: true, children: _jsx("span", { className: "tooltip-text", children: translatedText }) }));
    }
    return (_jsx(_Fragment, { children: isCustom ? (_jsx(_Fragment, { children: (function () {
                if (infoType === InfoType.modal) {
                    return renderModalContent();
                }
                if (infoType === InfoType.tooltip) {
                    return renderTooltipContent();
                }
                return null;
            })() })) : (_jsx(_Fragment, { children: tolgee.t(translateKey) })) }));
}
export default TolgeeWrapper;
