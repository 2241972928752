var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";
import { useDebounce } from "helpers/hooks";
import { BaseButton, BaseIconButton, SearchField } from "components";
import { api } from "services";
import { stringifyContactNameAndCompany } from "helpers/contact";
import tolgee from "services/translation/tolgee";
import { stringifyAddress } from "helpers/general";
import { dataGridConfig } from "helpers/dataGridConfig";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { handleError } from "services/api/error";
function getAddressesString(addresses) {
    switch (addresses.length) {
        case 0:
            return "(".concat(tolgee.t({
                key: "generic.none",
            }), ")");
        case 1:
            return stringifyAddress(addresses[0]);
        default:
            return "".concat(stringifyAddress(addresses[0]), " (+").concat(addresses.length - 1, " ").concat(tolgee.t({
                key: "generic.more",
            }), ")");
    }
}
export default function ContactDirectoryList() {
    var _this = this;
    var _a = useState(""), search = _a[0], setSearch = _a[1];
    var debouncedSearchValue = useDebounce(search, 500);
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState("created_at"), orderedBy = _c[0], setOrderedBy = _c[1];
    var _d = useState("desc"), newOrder = _d[0], setNewOrder = _d[1];
    var _e = useState(), data = _e[0], setData = _e[1];
    var _f = useState(0), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = useState(25), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    var _h = useState(null), contactUuid = _h[0], setContactUuid = _h[1];
    var _j = useState(false), contactModal = _j[0], setContactModal = _j[1];
    var fetchData = useCallback(function (props) { return __awaiter(_this, void 0, void 0, function () {
        var order_by, page, per_page, order, search_term, desc, res, e_1, tableData;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    order_by = props.order_by, page = props.page, per_page = props.per_page, order = props.order, search_term = props.search_term;
                    desc = order === "desc";
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.contactDirectory.getContacts({
                            order_by: order_by,
                            page: page + 1,
                            per_page: per_page,
                            desc: desc,
                            search_term: search_term,
                        })];
                case 2:
                    res = _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    handleError(e_1);
                    setLoading(false);
                    return [2 /*return*/];
                case 4:
                    res = res.data;
                    tableData = __assign(__assign({}, dataGridConfig({ currentPage: currentPage, rowsPerPage: rowsPerPage, count: res.count })), { onSortModelChange: function (sortVal) {
                            if ((sortVal === null || sortVal === void 0 ? void 0 : sortVal.length) === 0) {
                                return;
                            }
                            setOrderedBy(sortVal[0].field);
                            setNewOrder(sortVal[0].sort);
                        }, onPaginationModelChange: function (val) {
                            setCurrentPage(val.page);
                            setRowsPerPage(val.pageSize);
                        }, columns: [
                            {
                                headerName: tolgee.t({
                                    key: "ops.name",
                                }),
                                flex: 1,
                                field: "person_name",
                                valueGetter: function (_, params) {
                                    return stringifyContactNameAndCompany(params.person_name, params.organization_name, params.salutation);
                                },
                            },
                            {
                                headerName: tolgee.t({
                                    key: "ops.addresses",
                                }),
                                flex: 1,
                                field: "addresses",
                                sortable: false,
                                valueGetter: function (_, params) {
                                    return getAddressesString(params.addresses);
                                },
                            },
                            {
                                headerName: tolgee.t({
                                    key: "ops.contact_points",
                                }),
                                flex: 1,
                                field: "emails",
                                sortable: false,
                                valueGetter: function (_, params) {
                                    var contacts = params.emails.concat(params.phones);
                                    return "".concat(contacts.length > 0
                                        ? contacts.join(", ")
                                        : "(".concat(tolgee.t({
                                            key: "generic.none",
                                        }), ")"));
                                },
                            },
                        ], rows: (_a = res === null || res === void 0 ? void 0 : res.result) === null || _a === void 0 ? void 0 : _a.map(function (i) {
                            return __assign(__assign({}, i), { id: i === null || i === void 0 ? void 0 : i.uuid });
                        }), onRowClick: function (params) {
                            setContactUuid(params.row.uuid);
                            setContactModal(true);
                        } });
                    setData(tableData);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [currentPage, rowsPerPage]);
    useEffect(function () {
        fetchData({
            order_by: orderedBy,
            page: currentPage,
            per_page: rowsPerPage,
            order: newOrder,
            search_term: debouncedSearchValue,
        });
    }, [newOrder, orderedBy, debouncedSearchValue, fetchData, currentPage, rowsPerPage]);
    var handleCloseModal = function (modalState) {
        fetchData({
            order_by: orderedBy,
            page: currentPage,
            per_page: rowsPerPage,
            order: newOrder,
            search_term: debouncedSearchValue,
        });
        setContactModal(modalState);
    };
    return (_jsx("div", { className: "box-outerlayout mx-auto max-w-7xl", children: _jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex space-x-2 items-center", children: [_jsx(BaseIconButton, { className: "border-full", onClick: function () {
                                return fetchData({
                                    order_by: orderedBy,
                                    page: currentPage,
                                    per_page: rowsPerPage,
                                    order: newOrder,
                                    search_term: debouncedSearchValue,
                                });
                            }, children: _jsx("div", { className: "w-4 h-4 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "refresh", color: "gray" }) }) }), _jsx("h1", { children: _jsx(T, { keyName: "ops.contact_directory" }) })] }), data ? (_jsxs(_Fragment, { children: [_jsx(SearchField, { className: "w-full", searchState: [search, setSearch] }), _jsx(DataGrid, __assign({ sx: {
                                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
                            } }, data, { loading: loading }))] })) : (!loading && (_jsx("div", { children: tolgee.t({
                        key: "billing.no_data",
                    }) }))), _jsx("div", { className: "self-end mt-2", children: _jsx(BaseButton, { onClick: function () {
                            setContactUuid(null);
                            setContactModal(true);
                        }, children: _jsx(T, { keyName: "ops.create_contact" }) }) }), _jsx(ContactModal, { contactUuid: contactUuid, modalState: [contactModal, handleCloseModal] })] }) }));
}
