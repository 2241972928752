export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "ADMIN";
    UserRole["CUSTOMER"] = "CUSTOMER";
    UserRole["DEV"] = "DEV";
    UserRole["MANAGER_CUSTOMER"] = "MANAGER_CUSTOMER";
    UserRole["MANAGER_DSR"] = "MANAGER_DSR";
    UserRole["MANAGER_FIN"] = "MANAGER_FIN";
    UserRole["MANAGER_FINANCIALREPORTING"] = "MANAGER_FINANCIALREPORTING";
    UserRole["MANAGER_GHOSTLOGIN"] = "MANAGER_GHOSTLOGIN";
    UserRole["PARTNER"] = "PARTNER";
})(UserRole || (UserRole = {}));
