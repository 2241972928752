var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Checkbox, IconButton, List, ListItem, ListItemText, ListSubheader, MenuItem, Select, } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import tolgee from "services/translation";
import { exemptions, specificExemptions } from "const/dsr";
import { BaseModal, EditableText } from "components";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck, reopenCheck } from "store/thunks";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { api } from "services";
import DeclineWithPreviewButton from "pages/Client/Case/DSR/ClientView/DeclineWithPreviewButton";
import { ArrayWithIndex } from "helpers/general";
import { notify } from "store/app";
import { ExemptionGroup } from "types/dsr/main";
import TolgeeWrapper from "pages/Client/Dashboard/Components/TolgeeWrapper";
import { handleError } from "services/api/error";
var categoriesList = function (caseData) {
    var _a;
    return (_a = {
            generic: exemptions
        },
        _a[caseData.type] = specificExemptions[caseData.type],
        _a);
};
export default function ExcludeData() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var appDispatch = useDispatch();
    var caseData = state.case;
    var caseClosed = caseData.state === "CLOSED";
    var isFinished = isCheckFinished(state);
    var _b = useState([]), exemptionList = _b[0], setExemptionList = _b[1];
    var _c = useState([]), selectedExemptions = _c[0], setSelectedExemptions = _c[1];
    var _d = useState(false), openExemptionsModal = _d[0], setOpenExemptionsModal = _d[1];
    var _e = useState(false), showOptionalExemptions = _e[0], setShowOptionalExemptions = _e[1];
    var _f = useState([]), exemptionCategories = _f[0], setExemptionCategories = _f[1];
    var _g = useState(false), openCategories = _g[0], setOpenCategories = _g[1];
    useEffect(function () {
        setExemptionCategories(caseData.outcome.exemption_categories);
    }, [caseData]);
    var loadSelectedExemptions = useCallback(function (exemptionsList) {
        var filteredExemptions = exemptionsList
            .filter(function (filterItem) {
            return caseData.outcome.exemptions.some(function (selected) { return selected.name === filterItem.key; });
        })
            .map(function (mapItem) {
            var matchingExemption = caseData.outcome.exemptions.find(function (selected) { return selected.name === mapItem.key; });
            return __assign(__assign({}, mapItem), { comment: matchingExemption ? matchingExemption.comment : "" });
        });
        setSelectedExemptions(filteredExemptions);
    }, [caseData.outcome.exemptions]);
    var generateExemptionGroups = useCallback(function () {
        var groups = [];
        switch (caseData.type) {
            case "DSR_CCPA_DELETE":
                groups = [
                    ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_DELETE,
                    ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_DELETE,
                ];
                break;
            case "DSR_CCPA_ACCESS":
                groups = [
                    ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_ACCESS,
                    ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_ACCESS,
                ];
                break;
            case "DSR_CCPA_ACCESS_CATEGORIES":
                groups = [
                    ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_ACCESS_CATEGORIES,
                    ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_ACCESS_CATEGORIES,
                ];
                break;
            case "DSR_CCPA_CORRECT":
                groups = [
                    ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_CORRECT,
                    ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_CORRECT,
                ];
                break;
            case "DSR_CCPA_OPTOUT":
                groups = [
                    ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_OPTOUT,
                    ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_OPTOUT,
                ];
                break;
            case "DSR_CCPA_LIMIT":
                groups = [
                    ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_LIMIT,
                    ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_LIMIT,
                ];
                break;
            default:
                return [];
        }
        return groups;
    }, [caseData.type]);
    var fetchExemptions = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var groups, res, e_1, list, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    groups = generateExemptionGroups();
                    res = void 0;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.dsr.getExemptionsList(groups)];
                case 2:
                    res = _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 4:
                    list = res.data.map(function (item) {
                        return __assign(__assign({}, item), { comment: "" });
                    });
                    setExemptionList(list);
                    loadSelectedExemptions(list);
                    return [3 /*break*/, 6];
                case 5:
                    _a = _b.sent();
                    appDispatch(notify({
                        message: tolgee.t({
                            key: "generic.smth_went_wrong",
                        }),
                        type: "ERROR",
                    }));
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [setExemptionList, loadSelectedExemptions, generateExemptionGroups, appDispatch]);
    useEffect(function () {
        fetchExemptions();
    }, [fetchExemptions]);
    var exemptionCategoryChecked = function (category) { return exemptionCategories.includes(category); };
    var putExemptionsToBackend = function (exemptionsForBackend) { return __awaiter(_this, void 0, void 0, function () {
        var preparedExemptions, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    preparedExemptions = exemptionsForBackend.map(function (checkedItem) {
                        return {
                            name: checkedItem.key,
                            comment: checkedItem.comment,
                        };
                    });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.dsr.putExemptions(caseData.uuid, preparedExemptions)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmitExemptions = function (shouldFinalize) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            putExemptionsToBackend(selectedExemptions);
            if (shouldFinalize) {
                finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
                if (caseData.type === "DSR_CCPA_ACCESS" || caseData.type === "DSR_CCPA_DELETE") {
                    finalizeCheck()(dispatch, state.case.uuid, "EXCLUDE_DATA");
                }
                if (caseData.type === "DSR_CCPA_ACCESS_CATEGORIES") {
                    return [2 /*return*/];
                }
                if (caseData.type === "DSR_CCPA_DELETE") {
                    finalizeCheck()(dispatch, state.case.uuid, "DELETE_COMPLY", "DELETE_FULLY_COMPLIED");
                }
                else if (caseData.type === "DSR_CCPA_LIMIT") {
                    finalizeCheck()(dispatch, state.case.uuid, "LIMIT_COMPLY", "LIMIT_FULLY_COMPLIED");
                }
                else if (caseData.type === "DSR_CCPA_OPTOUT") {
                    finalizeCheck()(dispatch, state.case.uuid, "OPTOUT_COMPLY", "OPTOUT_FULLY_COMPLIED");
                }
                else {
                    finalizeCheck()(dispatch, state.case.uuid, "ACCESS_COMPLY", "ACCESS_FULLY_COMPLIED");
                }
            }
            setOpenExemptionsModal(false);
            return [2 /*return*/];
        });
    }); };
    var handleSubmitExemptionCategories = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.dsr.putExemptionCategories(caseData.uuid, exemptionCategories)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    handleError(e_3);
                    return [2 /*return*/];
                case 3:
                    finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
                    if (caseData.type === "DSR_CCPA_ACCESS" || caseData.type === "DSR_CCPA_DELETE") {
                        finalizeCheck()(dispatch, state.case.uuid, "EXCLUDE_DATA");
                    }
                    if (caseData.type === "DSR_CCPA_DELETE") {
                        finalizeCheck()(dispatch, state.case.uuid, "DELETE_COMPLY", "DELETE_PARTIALLY_COMPLIED");
                    }
                    else if (caseData.type === "DSR_CCPA_LIMIT") {
                        finalizeCheck()(dispatch, state.case.uuid, "LIMIT_COMPLY", "LIMIT_PARTIALLY_COMPLIED");
                    }
                    else if (caseData.type === "DSR_CCPA_OPTOUT") {
                        finalizeCheck()(dispatch, state.case.uuid, "OPTOUT_COMPLY", "OPTOUT_PARTIALLY_COMPLIED");
                    }
                    else {
                        finalizeCheck()(dispatch, state.case.uuid, "ACCESS_COMPLY", "ACCESS_PARTIALLY_COMPLIED");
                    }
                    setOpenCategories(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var generateDeclineType = function () {
        var type = "";
        if (caseData.type.includes("LIMIT")) {
            type = "LIMIT";
        }
        else if (caseData.type.includes("ACCESS")) {
            type = "ACCESS";
        }
        else if (caseData.type.includes("OPTOUT")) {
            type = "OPTOUT";
        }
        else {
            type = "DELETE";
        }
        var value = "".concat(type, "_NOT_COMPLIED");
        return value;
    };
    var handleExemptionCheck = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
        var currentIndex, newChecked;
        return __generator(this, function (_a) {
            currentIndex = selectedExemptions.findIndex(function (exemption) { return exemption.key === item.key; });
            newChecked = __spreadArray([], selectedExemptions, true);
            if (currentIndex === -1) {
                newChecked.push(item);
            }
            else {
                newChecked.splice(currentIndex, 1);
                putExemptionsToBackend(newChecked);
            }
            setSelectedExemptions(newChecked);
            return [2 /*return*/];
        });
    }); }; };
    var updateExemptionComment = function (key, value) {
        setSelectedExemptions(function (prev) {
            return prev.map(function (selectedExemption) {
                return selectedExemption.key === key ? __assign(__assign({}, selectedExemption), { comment: value }) : selectedExemption;
            });
        });
    };
    return (_jsxs("div", { className: "flex flex-col space-y-4", children: [_jsx("div", { className: "self-end", children: _jsx(Button, { "data-testid": "dsr_confirmation_button", color: "success", onClick: function () {
                        setShowOptionalExemptions(false);
                        setOpenExemptionsModal(true);
                    }, disabled: caseData.type === "DSR_CCPA_ACCESS_CATEGORIES"
                        ? isFinished
                        : isFinished || !!caseData.outcome.exemptions.length, children: tolgee.t({
                        key: generateI18nKey(caseData.type, state.ui.check, "confirm"),
                    }) }) }), _jsx("div", { className: "mt-4", children: tolgee.t({
                    key: generateI18nKey(caseData.type, state.ui.check, "description"),
                }) }), _jsxs("div", { children: [_jsx("h2", { className: "m-0", children: tolgee.t({
                            key: generateI18nKey(caseData.type, state.ui.check, "title"),
                        }) }), _jsx("h3", { className: "my-1", children: tolgee.t({
                            key: generateI18nKey(caseData.type, state.ui.check, "subtitle"),
                        }) }), tolgee.t({
                        key: generateI18nKey(caseData.type, state.ui.check, "explanation"),
                    })] }), _jsx(Select, { disabled: isFinished, open: false, onOpen: function () {
                    setShowOptionalExemptions(true);
                    setOpenExemptionsModal(true);
                }, displayEmpty: true, MenuProps: {
                    sx: { width: "fit-content", maxHeight: "50vh" },
                }, renderValue: function () { return (_jsx("em", { children: tolgee.t({
                        key: generateI18nKey(caseData.type, state.ui.check, "select_placeholder"),
                    }) })); }, value: "" }), _jsx("div", { className: "flex flex-row flex-wrap gap-4", children: selectedExemptions.map(function (item) { return (_jsxs("div", { children: [_jsxs("div", { className: "bg-[#f1f5f9] p-2 rounded-md rounded-bl-none flex items-center justify-between", children: [_jsx(TolgeeWrapper, { translateKey: item.key }), _jsx(IconButton, { disabled: isFinished, onClick: handleExemptionCheck(item), children: _jsx(FontAwesomeIcon, { icon: "circle-xmark", size: "sm" }) })] }), _jsxs("div", { className: "bg-[#f8fafc] mr-2 p-1 pl-2 flex flex-row items-center", children: [_jsx("b", { children: "Comment: " }), _jsx(EditableText, { value: item.comment, confirmation: false, onChange: function (value) { return updateExemptionComment(item.key, value); }, parentSubmit: function () { return handleSubmitExemptions(false); } })] })] }, item.key)); }) }), _jsxs("div", { className: "flex justify-between", children: [_jsx("div", {}), _jsxs("div", { className: "flex flex-row self-end space-x-2", children: [isFinished && !caseClosed && (_jsx(Button, { onClick: function () {
                                    reopenCheck()(dispatch, caseData.uuid, state.ui.check);
                                    if (caseData.type === "DSR_CCPA_ACCESS" || caseData.type === "DSR_CCPA_DELETE") {
                                        reopenCheck()(dispatch, state.case.uuid, "EXCLUDE_DATA");
                                    }
                                    if (caseData.type === "DSR_CCPA_ACCESS_CATEGORIES") {
                                        return;
                                    }
                                    if (caseData.type === "DSR_CCPA_DELETE") {
                                        reopenCheck()(dispatch, caseData.uuid, "DELETE_COMPLY");
                                    }
                                    else if (caseData.type === "DSR_CCPA_LIMIT") {
                                        reopenCheck()(dispatch, caseData.uuid, "LIMIT_COMPLY");
                                    }
                                    else if (caseData.type === "DSR_CCPA_OPTOUT") {
                                        reopenCheck()(dispatch, caseData.uuid, "OPTOUT_COMPLY");
                                    }
                                    else {
                                        reopenCheck()(dispatch, caseData.uuid, "ACCESS_COMPLY");
                                    }
                                }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), children: tolgee.t({
                                    key: "general.reopen",
                                }) })), caseData.type !== "DSR_CCPA_ACCESS_CATEGORIES" ? (_jsxs("div", { className: "space-x-4", children: [_jsx(DeclineWithPreviewButton, { label: tolgee.t({
                                            key: generateI18nKey(caseData.type, state.ui.check, "decline_open_exclude_data"),
                                        }), labelSubmit: tolgee.t({
                                            key: generateI18nKey(caseData.type, state.ui.check, "decline_submit_exclude_data"),
                                        }), disabled: !caseData.outcome.exemptions.length || isFinished, declineType: generateDeclineType() }), _jsx(Button, { onClick: function () { return setOpenCategories(true); }, disabled: !caseData.outcome.exemptions.length || isFinished, children: tolgee.t({
                                            key: generateI18nKey(caseData.type, state.ui.check, "partially_comply"),
                                        }) })] })) : ("")] })] }), _jsx(BaseModal, { modalState: [openExemptionsModal, setOpenExemptionsModal], children: _jsxs("div", { "data-testid": "excludeDataModal", className: "flex flex-col", children: [_jsx("h2", { className: "text-prighterdark", children: showOptionalExemptions ? (_jsx("span", { children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "modal_title"),
                                }) })) : (_jsx("span", { children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "modal_obligatory"),
                                }) })) }), _jsx("div", { children: tolgee.t({
                                key: generateI18nKey(caseData.type, state.ui.check, "modal_description"),
                            }) }), _jsxs("div", { className: "space-y-4 pt-4", children: [_jsx("div", { className: "bg-danger-400/10 border-danger-700 border-2 rounded-lg", children: _jsx(List, { className: !showOptionalExemptions ? "pointer-events-none" : "", children: exemptionList
                                            .filter(function (item) { return item.group.includes("OBLIGATION"); })
                                            .map(function (item, index) { return (_jsxs(ListItem, { className: "flex flex-row gap-4", children: [_jsx(ListItemText, { children: _jsx(TolgeeWrapper, { translateKey: item.key }) }), showOptionalExemptions ? (_jsx(Checkbox, { edge: "start", checked: selectedExemptions.some(function (exemption) { return exemption.key === item.key; }), onClick: handleExemptionCheck(item) })) : ("")] }, index)); }) }) }), showOptionalExemptions ? (_jsx("div", { className: "bg-gray-400/10 border-gray-700 border-2 rounded-lg", children: _jsx(List, { children: exemptionList
                                            .filter(function (item) { return item.group.includes("OPTIONAL"); })
                                            .map(function (item, index) { return (_jsxs(ListItem, { className: "flex flex-row gap-4", children: [_jsx(ListItemText, { children: _jsx(TolgeeWrapper, { translateKey: item.key }) }), _jsx(Checkbox, { edge: "start", checked: selectedExemptions.some(function (exemption) { return exemption.key === item.key; }), onClick: handleExemptionCheck(item) })] }, index)); }) }) })) : ("")] }), _jsx("div", { className: "flex mt-4 self-end", children: showOptionalExemptions ? (_jsx(Button, { "data-testid": "excludeDataModal_confirmReasons", onClick: function () { return handleSubmitExemptions(false); }, children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "confirm_reasons"),
                                }) })) : (_jsx(Button, { "data-testid": "excludeDataModal_confirm", onClick: function () { return handleSubmitExemptions(true); }, children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "confirm"),
                                }) })) })] }) }), _jsx(BaseModal, { modalState: [openCategories, setOpenCategories], children: _jsxs("div", { className: "flex flex-col w-[64rem] ", children: [_jsx("h2", { className: "text-prighterdark my-2", children: tolgee.t({
                                key: generateI18nKey(caseData.type, state.ui.check, "exemption_category.modal_title"),
                            }) }), _jsx("h3", { className: "text-prighterdark m-0", children: tolgee.t({
                                key: generateI18nKey(caseData.type, state.ui.check, "exemption_category.modal_subtitle"),
                            }) }), tolgee.t({
                            key: generateI18nKey(caseData.type, state.ui.check, "exemption_category.modal_description"),
                        }), _jsx(Select, { disabled: isFinished, value: exemptionCategories, multiple: true, displayEmpty: true, onChange: function (event) { return setExemptionCategories(event.target.value); }, MenuProps: {
                                sx: { width: "fit-content", maxHeight: "50vh" },
                            }, renderValue: function () { return (_jsx("em", { children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "partially.select_placeholder"),
                                }) })); }, children: Object.entries(categoriesList(state.case)).reduce(function (listItems, _a) {
                                var exemptionGroup = _a[0], exemptionss = _a[1];
                                return __spreadArray(__spreadArray([], listItems, true), [
                                    _jsxs(ListSubheader, { children: [tolgee.t({
                                                key: generateI18nKey(caseData.type, state.ui.check, "exemptions.".concat(exemptionGroup)),
                                            }), "asd"] }, exemptionGroup),
                                    Object.entries(exemptionss).reduce(function (subList, _a) {
                                        var exemptionType = _a[0], exemptionCount = _a[1];
                                        return __spreadArray(__spreadArray([], subList, true), [
                                            ArrayWithIndex(exemptionCount).map(function (index) { return (_jsx(MenuItem, { value: "exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index), style: {
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word",
                                                    overflowWrap: "break-word",
                                                }, children: _jsxs("div", { className: "h-8 flex flex-row items-center w-full justify-between", children: [tolgee.t({
                                                            key: generateI18nKey(caseData.type, state.ui.check, "exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index)),
                                                        }), exemptionCategoryChecked("exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index)) && _jsx(Checkbox, { checked: true })] }) }, index)); }),
                                        ], false);
                                    }, []),
                                ], false);
                            }, []) }), _jsx("div", { className: "mt-2 flex flex-row flex-wrap gap-4", children: exemptionCategories.map(function (category) { return (_jsxs("div", { className: "bg-[#f1f5f9] p-2 rounded-md rounded-bl-none w-fit flex items-center justify-between", children: [tolgee.t({
                                        key: generateI18nKey(caseData.type, state.ui.check, category),
                                    }), _jsx(IconButton, { onClick: function () {
                                            return setExemptionCategories(exemptionCategories.filter(function (exemption) { return exemption !== category; }));
                                        }, children: _jsx(FontAwesomeIcon, { icon: "circle-xmark", size: "sm" }) })] }, category)); }) }), _jsx("div", { className: "flex mt-4 self-end", children: _jsx(Button, { onClick: handleSubmitExemptionCategories, children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "partially_comply"),
                                }) }) })] }) })] }));
}
