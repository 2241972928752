import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Global from "components/DSRSettings/GlobalView";
import CCPA from "components/DSRSettings/CCPAView";
import tolgee from "services/translation";
import { BaseTabs } from "components/Tabs/Tabs";
import { useAppSelector } from "store";
export default function DSRSettings() {
    var _a, _b;
    var managed_businesses = useAppSelector(function (state) { return state.user; }).managed_businesses;
    var businessId = useParams().businessId;
    var _c = useState(), isPrivacyPolicyExists = _c[0], setIsPrivacyPolicyExists = _c[1];
    useEffect(function () {
        var _a, _b;
        var value = !!((_b = (_a = managed_businesses === null || managed_businesses === void 0 ? void 0 : managed_businesses.find(function (item) { return item.public_id === businessId; })) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.privacy_policy_ext_url);
        setIsPrivacyPolicyExists(value);
    }, [managed_businesses, businessId]);
    var tabsData = [
        {
            id: 1,
            label: tolgee.t("dsr.settings.tabs.global"),
            content: _jsx(Global, {}),
            isAvailable: true,
            testId: "globalTab",
        },
        {
            id: 2,
            label: (_jsxs("div", { className: "flex flex-row items-center gap-2", children: [tolgee.t("dsr.settings.tabs.ccpa"), !isPrivacyPolicyExists ? (_jsx("span", { className: "items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 rounded-full bg-danger-400", children: _jsx(FontAwesomeIcon, { icon: "exclamation-triangle", color: "navigation", fixedWidth: true }) })) : ("")] })),
            content: _jsx(CCPA, {}),
            isAvailable: ((_b = (_a = managed_businesses === null || managed_businesses === void 0 ? void 0 : managed_businesses.find(function (item) { return item.public_id === businessId; })) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.includes("ccpa")) || false,
            testId: "ccpaTab",
        },
    ];
    return (_jsx("div", { className: "@container mx-auto max-w-7xl", children: _jsx(BaseTabs, { tabsData: tabsData.filter(function (item) { return item.isAvailable; }), defaultTab: 0 }) }));
}
