var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, TextField } from "@mui/material";
import { BaseButton, BaseSelectField, BaseTextField, SplitButton } from "components";
import { api } from "services";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import { emptyContact } from "const/emptyObjects";
import { sanitizeAddresses, sanitizeContactPoints, stringifyContactNameAndCompany, } from "helpers/contact";
import tolgee from "services/translation";
import { PRIGHTER_BLUE } from "const/color";
import ContactPointList from "pages/Client/ContactDirectory/Components/ContactPointList";
import ContactAddress from "pages/Client/ContactDirectory/Components/ContactAddress";
import { objectDeepCopy } from "helpers";
import { serializeContactDirectory } from "helpers/api";
import { handleError } from "services/api/error";
export default function ContactEdit(_a) {
    var _this = this;
    var _b, _c;
    var contactUuid = _a.contactUuid, setModal = _a.setModal, _d = _a.companyTitle, companyTitle = _d === void 0 ? "company" : _d, _e = _a.emailRequired, emailRequired = _e === void 0 ? false : _e, parentSubmit = _a.parentSubmit;
    var dispatch = useAppDispatch();
    var _f = useState(emptyContact), contact = _f[0], setContact = _f[1];
    var _g = useState(emptyContact), constContact = _g[0], setConstContact = _g[1];
    var _h = useState([]), permissions = _h[0], setPermissions = _h[1];
    var salutationOptions = { mr: "Mr.", mrs: "Mrs." };
    var selectedIndex = useState(0);
    var _j = useState(false), comment = _j[0], setComment = _j[1];
    var fetchContact = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.contactDirectory.getContact(contactUuid)];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 3:
                    res = serializeContactDirectory(res.data);
                    if (res) {
                        setContact(res);
                        setConstContact(objectDeepCopy(res));
                        if (res.comment) {
                            setComment(true);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [contactUuid]);
    var getPermissions = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.security.getPermissions("BUSINESS", "WRITE")];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [2 /*return*/];
                case 3:
                    res = res.data;
                    setPermissions(res);
                    if ((res === null || res === void 0 ? void 0 : res.length) === 1) {
                        setContact(function (oldContact) {
                            var _a;
                            return (__assign(__assign({}, oldContact), { business: {
                                    public_id: (_a = res[0]) === null || _a === void 0 ? void 0 : _a.target_public_id,
                                } }));
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        setContact(emptyContact);
        if (contactUuid) {
            fetchContact();
        }
        getPermissions();
        if (emailRequired) {
            handleIncrease("EMAIL");
        }
    }, [contactUuid, emailRequired, fetchContact]);
    var createItems = [
        {
            name: "Address",
            action: function () {
                return setContact(function (prevContact) { return (__assign(__assign({}, prevContact), { addresses: __spreadArray(__spreadArray([], prevContact.addresses, true), [
                        { country_iso: "", city: "", street: "", postal: "" },
                    ], false) })); });
            },
            icon: "plus",
        },
        {
            name: "Phone",
            action: function () { return handleIncrease("PHONE"); },
            icon: "plus",
        },
        {
            name: "Email",
            action: function () { return handleIncrease("EMAIL"); },
            icon: "plus",
        },
        {
            name: "Web",
            action: function () { return handleIncrease("WEB"); },
            icon: "plus",
        },
        {
            name: "Comment",
            action: function () { return setComment(true); },
            icon: "plus",
        },
    ];
    var handleUpsert = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, businessId, contactData, sanitizedContact, e_3, e_4;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    businessId = contact.business.public_id;
                    if (emailRequired &&
                        (contact.contact_points.findIndex(function (t) { return t.type === "EMAIL"; }) === -1 ||
                            contact.contact_points.find(function (t) { return t.type === "EMAIL"; }).value.length < 5)) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "error.email_is_required",
                            }),
                            type: "ERROR",
                        }));
                        return [2 /*return*/];
                    }
                    if (!(((_a = contact.name) === null || _a === void 0 ? void 0 : _a.length) >= 3 || ((_b = contact.organization) === null || _b === void 0 ? void 0 : _b.length) >= 3)) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "error.contact_directory.name_or_org_name_should_exist",
                            }),
                            type: "ERROR",
                        }));
                        return [2 /*return*/];
                    }
                    contactData = Object.entries(contact)
                        .filter(function (_a) {
                        var key = _a[0];
                        return key !== "business";
                    })
                        .reduce(function (acc, _a) {
                        var key = _a[0], value = _a[1];
                        acc[key] = value;
                        return acc;
                    }, {});
                    sanitizedContact = __assign(__assign({}, contactData), { uuid: null, addresses: sanitizeAddresses(contactData.addresses), contact_points: sanitizeContactPoints(contactData.contact_points) });
                    if (!contactUuid) return [3 /*break*/, 5];
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.contactDirectory.updateContact(businessId, contactUuid, sanitizedContact)];
                case 2:
                    res = _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _c.sent();
                    handleError(e_3);
                    return [2 /*return*/];
                case 4: return [3 /*break*/, 9];
                case 5:
                    _c.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, api.contactDirectory.createContact(businessId, sanitizedContact)];
                case 6:
                    res = _c.sent();
                    return [3 /*break*/, 8];
                case 7:
                    e_4 = _c.sent();
                    handleError(e_4);
                    return [2 /*return*/];
                case 8:
                    if (parentSubmit) {
                        parentSubmit(res.data);
                    }
                    _c.label = 9;
                case 9:
                    dispatch(notify({
                        message: "".concat(tolgee.t({
                            key: "ops.contact_successfully",
                        }), " ").concat(contactUuid
                            ? tolgee.t({
                                key: "ops.updated",
                            })
                            : tolgee.t({
                                key: "ops.created",
                            })),
                        type: "SUCCESS",
                    }));
                    setModal(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function () {
        try {
            api.contactDirectory.deleteContact(contact.business.public_id, contactUuid).then(function () {
                dispatch(notify({
                    message: tolgee.t({
                        key: "ops.contact_successfully_created",
                    }),
                    type: "SUCCESS",
                }));
                setModal(false);
            });
        }
        catch (e) {
            handleError(e);
        }
    };
    var handleIncrease = function (type) {
        setContact(function (prevContact) { return (__assign(__assign({}, prevContact), { contact_points: __spreadArray(__spreadArray([], prevContact.contact_points, true), [{ type: type, value: "" }], false) })); });
    };
    return (_jsxs(_Fragment, { children: [_jsx("h2", { className: "mt-0 text-brand-900", children: contactUuid
                    ? "".concat(tolgee.t({
                        key: "ops.contact",
                    }), " ").concat(stringifyContactNameAndCompany(constContact === null || constContact === void 0 ? void 0 : constContact.name, constContact === null || constContact === void 0 ? void 0 : constContact.organization, constContact === null || constContact === void 0 ? void 0 : constContact.salutation))
                    : tolgee.t({
                        key: "ops.create_contact",
                    }) }), _jsxs("div", { className: "flex flex-col space-y-4", "data-testid": "contact-form", children: [(permissions === null || permissions === void 0 ? void 0 : permissions.length) > 1 && (_jsxs("div", { className: "flex items-center space-x-4", children: [_jsx(FontAwesomeIcon, { icon: "building", color: PRIGHTER_BLUE }), _jsx(BaseSelectField, { fullWidth: true, label: tolgee.t({
                                    key: "contacts.select_business",
                                }), options: permissions.reduce(function (acc, item) {
                                    acc[item.target_public_id] = "".concat(item.target_name, " (").concat(item.target_public_id, ")");
                                    return acc;
                                }, {}), defaultValue: "", state: [
                                    ((_b = contact.business) === null || _b === void 0 ? void 0 : _b.public_id) || "",
                                    function (val) {
                                        setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { business: {
                                                public_id: val,
                                            } })); });
                                    },
                                ], required: true })] })), _jsxs("div", { className: "flex items-center space-x-4", children: [_jsx(FontAwesomeIcon, { icon: "user", color: PRIGHTER_BLUE, className: "w-4" }), _jsx(BaseSelectField, { className: "w-24", fullWidth: false, label: tolgee.t({
                                    key: "contacts.title",
                                }), options: salutationOptions, defaultValue: "", state: [
                                    contact.salutation || "",
                                    function (option) {
                                        return setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { salutation: option })); });
                                    },
                                ] }), _jsx(TextField, { className: "w-full", InputLabelProps: { shrink: true }, value: contact.name, onChange: function (event) {
                                    return setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { name: event.target.value })); });
                                }, label: tolgee.t({
                                    key: "contacts.name",
                                }) })] }), _jsxs("div", { className: "flex items-center w-full space-x-4", children: [_jsx(FontAwesomeIcon, { icon: "building", color: PRIGHTER_BLUE, className: "w-4" }), _jsx(TextField, { InputLabelProps: { shrink: true }, label: tolgee.t({
                                    key: "contacts.".concat(companyTitle),
                                }), className: "w-full", value: contact.organization, onChange: function (event) {
                                    return setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { organization: event.target.value })); });
                                } })] }), _jsx(ContactPointList, { contactPoints: contact.contact_points, onChange: function (contactPoints) {
                            return setContact(function (prevContact) { return (__assign(__assign({}, prevContact), { contact_points: contactPoints })); });
                        } }), _jsx(ContactAddress, { contactAddresses: contact.addresses, onChange: function (contactAddresses) {
                            return setContact(function (prevContact) { return (__assign(__assign({}, prevContact), { addresses: contactAddresses })); });
                        } }), comment && (_jsxs("div", { className: "flex space-x-4", children: [_jsx(FontAwesomeIcon, { icon: "comment", color: PRIGHTER_BLUE }), _jsx(BaseTextField, { containerClassName: "w-full", multiline: true, value: contact.comment, onChange: function (event) {
                                    return setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { comment: event.target.value })); });
                                }, label: tolgee.t({
                                    key: "generic.comment",
                                }) }), _jsx("div", { className: "flex py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50", children: _jsxs(IconButton, { "data-testid": "remove-address-button", onClick: function () {
                                        setComment(false);
                                        setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { comment: null })); });
                                    }, size: "large", children: [_jsx(FontAwesomeIcon, { icon: "trash", className: " text-brand-600", size: "xs" }), " "] }) })] }))] }), _jsxs("div", { className: "flex self-end w-full mt-4 space-x-2", children: [_jsx("div", { className: "flex items-center justify-center w-full", children: _jsx(SplitButton, { items: createItems, state: selectedIndex }) }), contactUuid && (_jsx(BaseButton, { onClick: handleDelete, color: "error", testid: "delete-button", children: _jsx(T, { keyName: "generic.delete" }) })), _jsx(BaseButton, { disabled: !((_c = contact === null || contact === void 0 ? void 0 : contact.business) === null || _c === void 0 ? void 0 : _c.public_id), onClick: handleUpsert, testid: contactUuid ? "save-button" : "create-button", children: contactUuid
                            ? tolgee.t({
                                key: "generic.save",
                            })
                            : tolgee.t({
                                key: "generic.create",
                            }) })] })] }));
}
