import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { IconButton, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { FormFileField } from "components";
import tolgee from "services/translation";
import TextCertificate from "pages/Client/LandingPage/TextCertificate";
export default function CertificationsPage(_a) {
    var handleUploadFile = _a.handleUploadFile, handleRemoveFile = _a.handleRemoveFile;
    var watch = useFormContext().watch;
    var cookie = watch("cookie_policy");
    var certImages = watch("certificate_image_urls", []);
    return (_jsxs("div", { className: "p-3", children: [_jsxs(Stack, { spacing: 2, children: [_jsx("div", { className: "font-bold", children: _jsx(T, { keyName: "clp.upload_up_to_4_certificates" }) }), _jsx("div", { children: _jsx(T, { keyName: "clp.image_recommendations" }) }), _jsxs("div", { className: "flex items-start w-full space-x-2", children: [_jsx(FormFileField, { label: tolgee.t({ key: "clp.choose_file" }), className: "mt-2", value: (cookie === null || cookie === void 0 ? void 0 : cookie.name) && [{ name: cookie.name }], onChange: function (files) { return handleUploadFile(files[0], "imageCertificate"); }, fullWidth: true }), _jsx("div", { className: "flex gap-2 flex-wrap", children: certImages.map(function (certImage) { return (_jsxs("div", { className: "flex items-start", children: [_jsx("img", { alt: "certificate url", style: { maxHeight: "300px", maxWidth: "300px" }, src: certImage }), _jsx(IconButton, { onClick: function () {
                                                return handleRemoveFile("CERTIFICATE_IMAGE", new URL(certImage).searchParams.get("cdn_path"));
                                            }, size: "small", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "xmark" }) })] }, certImage)); }) })] })] }), _jsx(TextCertificate, {})] }));
}
