var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useState } from "react";
var useTable = function () {
    var _a = useState({
        settings: {},
        keys: [],
        values: [],
    }), tableConfig = _a[0], setTableConfig = _a[1];
    var setSettings = useCallback(function (value) {
        var fields = ["id"];
        if (value.ignoreFields) {
            fields = __spreadArray(["id"], value.ignoreFields, true);
        }
        var shouldSort = "shouldSort" in value ? value.shouldSort : true;
        setTableConfig(function (prevState) { return (__assign(__assign({}, prevState), { settings: __assign(__assign({}, value), { ignoreFields: __spreadArray([], fields, true), shouldSort: shouldSort }) })); });
    }, [setTableConfig]);
    var setKeys = useCallback(function (value) {
        setTableConfig(function (prevState) { return (__assign(__assign({}, prevState), { keys: value })); });
    }, [setTableConfig]);
    var setValues = useCallback(function (value) {
        setTableConfig(function (prevState) { return (__assign(__assign({}, prevState), { values: value })); });
    }, [setTableConfig]);
    return [tableConfig, setKeys, setValues, setSettings];
};
export default useTable;
