import * as user from "services/api/user";
import * as general from "services/api/general";
import * as authCase from "services/api/case";
import * as dsr from "services/api/dsr";
import * as billing from "services/api/billing";
import * as marketing from "services/api/marketing";
import * as contactDirectory from "services/api/contactDirectory";
import * as clp from "services/api/clp";
import * as security from "services/api/security";
import * as sales from "services/api/sales";
import * as ccpa from "services/api/dsr/ccpa";
import * as email from "services/api/email/index";
import * as fadp from "services/api/dsr/fadp";
import * as report from "services/api/report";
import * as business from "services/api/business";
import * as partner from "services/api/partner";
import * as ropa from "services/api/ropa";
import * as notifications from "services/api/notifications";
import * as vouchers from "services/api/vouchers";
import * as invoice from "services/api/invoice";
import * as message from "services/api/message";
import axiosClient from "services/api/base";
export default {
    user: user,
    general: general,
    authCase: authCase,
    dsr: dsr,
    billing: billing,
    marketing: marketing,
    sales: sales,
    clp: clp,
    contactDirectory: contactDirectory,
    security: security,
    ccpa: ccpa,
    email: email,
    message: message,
    fadp: fadp,
    report: report,
    business: business,
    partner: partner,
    ropa: ropa,
    notifications: notifications,
    vouchers: vouchers,
    invoice: invoice,
};
export { axiosClient as apiInstance };
