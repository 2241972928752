export var providerState = {
    case: {
        created_at: null,
        updated_at: null,
        title: null,
        type: null,
        state: null,
        uuid: null,
        histories: [],
        case_actors: [],
        business: null,
        email_alias: null,
        inquiries: [],
        status: null,
        deadline: null,
        closed_as: null,
        closed_at: null,
        detail: null,
        schedules: [],
        tags: [],
        files: [],
        outcome: null,
        ds_data: [],
        ds_email_verified: null,
        email_inbox: null,
        rights_concerned: [],
        case_stages: [],
        case_checks: [],
        extra: {
            acting_for: "MYSELF",
            ip_address: "",
            ip_geo_location: null,
            username: "",
            email: "",
            customer_number: "",
            other: "",
            phone: "",
            address: null,
        },
    },
    initialLoading: true,
    loading: true,
    inquiry: {
        open: false,
        questions: [],
    },
    ui: {
        stage: "PRE_CHECK",
        check: "DS_LOCATION",
    },
};
export default providerState;
