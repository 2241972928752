var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { EnhancedTable } from "components";
import { api } from "services";
import tolgee from "services/translation";
import useEnhancedTable from "helpers/hooks/useEnhancedTable";
import { handleError } from "services/api/error";
export default function ProductRevenue() {
    var _this = this;
    var _a = useEnhancedTable(), tableConfig = _a[0], setKeys = _a[1], setValues = _a[2], setSettings = _a[3];
    useEffect(function () {
        var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
            var monthHeader, total, repEU, repUK, repT, nisEU, nisUK, breach, drsArchival, res, e_1, tempValues;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        monthHeader = tolgee.t({
                            key: "report.month",
                        });
                        total = tolgee.t({
                            key: "report.total",
                        });
                        repEU = tolgee.t({
                            key: "report.rep_eu",
                        });
                        repUK = tolgee.t({
                            key: "report.rep_uk",
                        });
                        repT = tolgee.t({
                            key: "report.report_turkey",
                        });
                        nisEU = tolgee.t({
                            key: "report.nis_eu",
                        });
                        nisUK = tolgee.t({
                            key: "report.nis_uk",
                        });
                        breach = tolgee.t({
                            key: "report.breach",
                        });
                        drsArchival = tolgee.t({
                            key: "report.dsr_archival",
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, api.report.getProductRevenue()];
                    case 2:
                        res = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        handleError(e_1);
                        return [2 /*return*/];
                    case 4:
                        setKeys([monthHeader, total, repEU, repUK, repT, nisEU, nisUK, breach, drsArchival]);
                        tempValues = [];
                        res.data.forEach(function (reportItem, idx) {
                            var _a;
                            var _b = reportItem.month.split("-"), year = _b[0], month = _b[1];
                            var monthIndex = parseInt(month, 10) - 1;
                            tempValues.push({
                                cells: (_a = {},
                                    _a[monthHeader] = {
                                        displayedString: reportItem.month.toString(),
                                        value: new Date(parseInt(year, 10), monthIndex),
                                    },
                                    _a[total] = { displayedString: Number(reportItem.sum).toFixed(2) },
                                    _a[repEU] = { displayedString: Number(reportItem.rep_eu).toFixed(2) },
                                    _a[repUK] = { displayedString: Number(reportItem.rep_uk).toFixed(2) },
                                    _a[repT] = { displayedString: Number(reportItem.rep_turkey).toFixed(2) },
                                    _a[nisEU] = { displayedString: Number(reportItem.nis_eu).toFixed(2) },
                                    _a[nisUK] = { displayedString: Number(reportItem.nis_uk).toFixed(2) },
                                    _a[breach] = { displayedString: Number(reportItem.prighterbreach).toFixed(2) },
                                    _a[drsArchival] = { displayedString: Number(reportItem.dsr_archival).toFixed(2) },
                                    _a),
                                id: idx,
                            });
                        });
                        setValues(tempValues);
                        setSettings({
                            defaultSort: { key: monthHeader },
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [setKeys, setSettings, setValues]);
    return (_jsx("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "product-revenue", children: _jsx("div", { className: "box-outerlayout", children: tableConfig && _jsx(EnhancedTable, { config: tableConfig }) }) }));
}
