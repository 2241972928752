var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import tolgee from "services/translation";
export function stringifyAddress(address) {
    var str = address.postal || "";
    if (address.city) {
        str += str ? " ".concat(address.city) : address.city;
    }
    if (address.street) {
        str += str ? ", ".concat(address.street) : address.street;
    }
    if (address.country_iso) {
        str += str ? ", ".concat(address.country_iso) : address.country_iso;
    }
    return str || tolgee.t("generic.no_address");
}
export function getCountryName(countries, countryCode) {
    var country = countries.find(function (c) { return c.iso_3166_1_alpha_2 === countryCode; });
    return country ? country.name : countryCode;
}
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
export function isManager(roles) {
    return roles.some(function (role) { return ["ADMIN", "MANAGER_CUSTOMER", "MANAGER_DSR"].includes(role.name); });
}
export function getFontColor(hexColor) {
    if (hexColor) {
        // Convert hex to RGB
        var r = parseInt(hexColor.substring(1, 2), 16);
        var g = parseInt(hexColor.substring(3, 5), 16);
        var b = parseInt(hexColor.substring(5, 7), 16);
        // Calculate brightness
        var brightness = r * 0.299 + g * 0.587 + b * 0.114;
        // Return white for dark colors and black for light colors
        return brightness < 128 ? "#fff" : "#000";
    }
    return "";
}
export function ArrayWithIndex(length) {
    return __spreadArray([], Array(length), true).map(function (_, i) { return i; });
}
export function extractBusinessIdsFromNav(menu) {
    var ids = new Set();
    function collectIds(items) {
        items.forEach(function (item) {
            if (item.business_id) {
                ids.add(item.business_id);
            }
            if (item.sub_menu && item.sub_menu.length > 0) {
                collectIds(item.sub_menu);
            }
        });
    }
    collectIds(menu);
    return Array.from(ids);
}
