import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { ErrorBoundary } from "@sentry/react";
import NotFound from "pages/General/Error/NotFound";
import Redirect from "pages/General/Error/Redirect";
import ServerError from "pages/General/Error/ServerError";
import { useAppSelector } from "store";
import { setError } from "store/app";
import Header from "components/Layout/Header";
import Main from "components/Layout/Main";
import ErrorFallback from "services/errorHandling/ErrorFallback";
import Forbidden from "pages/General/Error/Forbidden";
export default function LoggedInLayout(props) {
    var _a = props.noLayout, noLayout = _a === void 0 ? false : _a;
    var errorCode = useAppSelector(function (state) { return state.app.error; });
    var location = useLocation();
    var dispatch = useDispatch();
    var didMountRef = useRef(false);
    useEffect(function () {
        if (didMountRef.current) {
            dispatch(setError(null));
        }
        else {
            didMountRef.current = true;
        }
    }, [dispatch, location]);
    var renderErrorSwitch = function () {
        switch (errorCode) {
            case "404":
                return _jsx(NotFound, {});
            case "500":
                return _jsx(ServerError, {});
            case "401":
                return _jsx(Redirect, {});
            case "403":
                return _jsx(Forbidden, {});
            default:
                return _jsx(Outlet, {});
        }
    };
    var fallback = function (_a) {
        var resetError = _a.resetError;
        return _jsx(ErrorFallback, { resetError: resetError });
    };
    return (_jsx("div", { className: "font-body flex flex-col min-h-screen relative mx-0 bg-gray-50", "data-testid": "logged-in-layout-element", children: noLayout ? (renderErrorSwitch()) : (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx(Main, { children: _jsx(ErrorBoundary, { fallback: fallback, children: renderErrorSwitch() }) })] })) }));
}
