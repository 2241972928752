import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TextField } from "@mui/material";
import { useState } from "react";
import { BaseButton, BaseModal } from "components";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { useCase } from "pages/Client/Case/CaseContext";
import { getBeautyDateTime } from "helpers";
import tolgee from "services/translation";
export default function HandlingReview() {
    var state = useCase()[0];
    var caseData = state.case;
    var _a = useState(), openCaseEmail = _a[0], setOpenCaseEmail = _a[1];
    var caseExemptions = caseData.outcome.exemptions;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "grid gap-1 px-6 mb-3", children: [_jsx("h2", { className: "mb-4", children: tolgee.t("dsr.closedCase.summary") }), _jsxs("span", { className: "mb-2", children: [tolgee.t("dsr.closedCase.closedAs"), " ", _jsx("strong", { children: tolgee.t("dsr.closedCase.".concat(caseData.closed_as)) })] }), _jsxs("div", { className: "flex flex-row gap-4", children: [_jsxs("span", { className: "mb-2", children: [_jsxs("strong", { children: [tolgee.t("generic.received"), ":"] }), " ", getBeautyDateTime(caseData.created_at), " "] }), _jsxs("span", { children: [_jsxs("strong", { children: [tolgee.t("generic.closed"), ":"] }), getBeautyDateTime(caseData.closed_at)] })] }), caseExemptions.length > 0 ? (_jsxs("div", { className: "mb-4", children: [_jsx("div", { className: "mb-2", children: tolgee.t("dsr.closedCase.exemptions_applied") }), _jsx("div", { className: "flex flex-col gap-2", children: caseExemptions.map(function (item) { return (_jsx("div", { className: "bg-brand-100 p-2 rounded-lg", children: item.name })); }) })] })) : ("")] }), caseData.outcome.email_content ? (_jsx("div", { className: "flex justify-between px-6", children: _jsx("div", { children: _jsx(BaseButton, { onClick: function () { return setOpenCaseEmail(true); }, children: tolgee.t("dsr.closedCase.view_closing_email") }) }) })) : (""), _jsx(BaseModal, { modalState: [openCaseEmail, setOpenCaseEmail], children: _jsxs("div", { className: "flex flex-col gap-4", children: [_jsx(TextField, { disabled: true, value: caseData.outcome.email_subject, label: "Subject", InputLabelProps: { shrink: true } }), _jsx(MarkdownEditor, { readOnly: true, value: caseData.outcome.email_content }), _jsx("div", { className: "flex justify-end", children: _jsx(BaseButton, { onClick: function () { return setOpenCaseEmail(false); }, children: tolgee.t("generic.close") }) })] }) })] }));
}
