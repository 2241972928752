var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Divider, IconButton, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import { PRIGHTER_BLUE } from "const/color";
import { api } from "services";
import { getBeautyDateTime, getUrl } from "helpers";
import tolgee from "services/translation";
import { handleError } from "services/api/error";
export default function Security() {
    var _this = this;
    var _a = useState(), data = _a[0], setData = _a[1];
    var _b = useState(0), reload = _b[0], setReload = _b[1];
    useEffect(function () {
        document.title = tolgee.t({
            key: "generic.security",
        });
        var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, api.security.getSecurityInfo()];
                    case 1:
                        res = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        handleError(e_1);
                        return [2 /*return*/];
                    case 3:
                        setData(res.data);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [reload]);
    var handleRemoveDevice = function (id) { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, api.security.removeDevice(id)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [2 /*return*/];
                case 3:
                    setReload(function (prev) { return prev + 1; });
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var renderPermissions = function () {
        if ((data === null || data === void 0 ? void 0 : data.permissions.length) > 0) {
            return data.permissions.map(function (permission) { return (_jsxs("div", { className: "flex items-center pt-2 pb-2", "data-testid": "permission", children: [_jsx(FontAwesomeIcon, { icon: "check", color: PRIGHTER_BLUE, size: "lg" }), _jsx("div", { className: "pl-2", children: permission })] }, permission)); });
        }
        return (_jsx("div", { "data-testid": "no-permissions", children: _jsx(T, { keyName: "billing.no_active_permissions" }) }));
    };
    var renderDevices = function () {
        return (_jsxs(List, { "data-testid": "devices-list", children: [_jsxs(ListItem, { sx: {}, divider: true, children: [_jsx(ListItemText, { sx: { width: "45%" }, children: _jsx(T, { keyName: "generic.id" }) }), _jsx(ListItemText, { sx: { width: "25%" }, children: _jsx(T, { keyName: "generic.validated" }) }), _jsx(ListItemText, { sx: { width: "25%" }, children: _jsx(T, { keyName: "generic.last_used" }) }), _jsx(ListItemText, { sx: { width: "5%" } })] }, "device_list_header"), data.mfa_devices.map(function (device) { return (_jsxs(ListItem, { children: [_jsx(ListItemText, { sx: { width: "45%" }, children: device.device_id }), _jsx(ListItemText, { sx: { width: "25%" }, children: device.validated_at ? (getBeautyDateTime(device.validated_at)) : (_jsx(T, { keyName: "generic.no" })) }), _jsx(ListItemText, { sx: { width: "25%" }, children: device.last_used_at ? (getBeautyDateTime(device.last_used_at)) : (_jsx(T, { keyName: "generic.never" })) }), _jsx(ListItemText, { sx: { width: "5%" }, children: _jsx(IconButton, { size: "small", onClick: function () { return handleRemoveDevice(device.device_id); }, children: _jsx(FontAwesomeIcon, { icon: "trash" }) }) })] }, device.device_id)); })] }));
    };
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "security-page", children: [_jsx("div", { className: "box-outerlayout mb-4", children: _jsxs("div", { className: "flex align-center flex-col", children: [_jsx("h1", { children: _jsx(T, { keyName: "generic.security" }) }), _jsx("div", { className: "flex justify-end space-x-2 mt-8", children: _jsx(Button, { href: getUrl("FLASK", "/psecurity/changepassword"), children: _jsx(T, { keyName: "generic.change_password" }) }) })] }) }), _jsx("div", { className: "box-outerlayout mb-4", children: _jsxs("div", { className: "flex align-center flex-col", children: [_jsx("h1", { children: _jsx(T, { keyName: "generic.tfa" }) }), _jsx(_Fragment, { children: (data === null || data === void 0 ? void 0 : data.mfa_devices.length) > 0 && renderDevices() }), _jsx("div", { className: "flex justify-end space-x-2 mt-8", children: _jsx(Button, { href: getUrl("FLASK", "/my/security/mfa/add"), children: _jsx(T, { keyName: "generic.add_device" }) }) })] }) }), _jsx("div", { className: "box-outerlayout mb-4", children: _jsxs("div", { className: "flex align-center flex-col", children: [_jsx("h1", { children: _jsx(T, { keyName: "generic.security_info" }) }), _jsxs(List, { children: [_jsxs(ListItem, { children: [_jsx(ListItemText, { sx: { width: "50%" }, children: _jsx(T, { keyName: "generic.last_login" }) }), _jsx(ListItemText, { sx: { width: "50%" }, children: getBeautyDateTime(data === null || data === void 0 ? void 0 : data.last_login_at) })] }), _jsx(Divider, {}), _jsxs(ListItem, { children: [_jsx(ListItemText, { sx: { width: "50%" }, children: _jsx(T, { keyName: "generic.current_login" }) }), _jsx(ListItemText, { sx: { width: "50%" }, children: getBeautyDateTime(data === null || data === void 0 ? void 0 : data.current_login_at) })] })] }), _jsx("h2", { children: _jsx(T, { keyName: "generic.permissions" }) }), _jsx("div", { className: "pl-4", children: renderPermissions() })] }) })] }));
}
