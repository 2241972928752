var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/* eslint-disable default-param-last */
import { createSlice } from "@reduxjs/toolkit";
import { DEV_ENV, IS_PROD_DEV } from "const/env";
export var initialState = {
    version: null,
    notification: { type: null, message: null, id: 0, duration: 3 },
    error: null,
    countries: [],
    development: { redirect: !(IS_PROD_DEV || DEV_ENV) },
    guide: { running: false, activeGuide: null, guideList: [] },
    preferences: {
        createTestCaseIndex: 0,
        navbarState: true,
        caseRows: 25,
        caseSortIndex: 0,
        selectedBusiness: null,
    },
};
export var appSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        notify: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { notification: __assign(__assign({}, previous_state.notification), { type: action.payload.type, message: action.payload.message, details: DEV_ENV ? action.payload.details : null, duration: action.payload.duration || 3, id: previous_state.notification.id + 1 }) }));
        },
        setError: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { error: action.payload }));
        },
        setCountries: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { countries: action.payload }));
        },
        setDevRedirection: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { development: __assign(__assign({}, previous_state.development), { redirect: action.payload }) }));
        },
        toggleGuideRunning: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { guide: __assign(__assign({}, previous_state.guide), { running: action.payload }) }));
        },
        changeGuide: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { guide: __assign(__assign({}, previous_state.guide), action.payload) }));
        },
        toggleNavbar: function (previous_state) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { preferences: __assign(__assign({}, previous_state.preferences), { navbarState: !previous_state.preferences.navbarState }) }));
        },
        syncPreferences: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { preferences: action.payload }));
        },
        changePreference: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { preferences: __assign(__assign({}, previous_state.preferences), action.payload) }));
        },
        setAppVersion: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            return (__assign(__assign({}, previous_state), { version: action.payload }));
        },
    },
});
export var notify = (_a = appSlice.actions, _a.notify), setError = _a.setError, setCountries = _a.setCountries, setDevRedirection = _a.setDevRedirection, changeGuide = _a.changeGuide, toggleGuideRunning = _a.toggleGuideRunning, toggleNavbar = _a.toggleNavbar, syncPreferences = _a.syncPreferences, changePreference = _a.changePreference, setAppVersion = _a.setAppVersion;
export default appSlice.reducer;
