var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import { useForm, FormProvider } from "react-hook-form";
import tolgee from "services/translation";
import { notify } from "store/app";
import { api } from "services";
import { fileToFileObject } from "helpers";
import { BaseTabs } from "components/Tabs/Tabs";
import CustomizeLandingPage from "pages/Client/LandingPage/CustomizeLandingPage/View";
import CompanyPolicies from "pages/Client/LandingPage/CompanyPolicies/View";
import CertificationsPage from "pages/Client/LandingPage/CertificationPage/View";
import { handleError } from "services/api/error";
var defaultColors = {
    primaryColor: "#4B637D",
    secondaryColor: "#FCFDFF",
};
export default function LandingPageSettings(_a) {
    var _this = this;
    var _b = _a.noTitle, noTitle = _b === void 0 ? false : _b;
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var methods = useForm();
    var _c = useState(null), customSlug = _c[0], setCustomSlug = _c[1];
    var _d = useState(defaultColors.primaryColor), primaryColorCandidate = _d[0], setPrimaryColorCandidate = _d[1];
    var _e = useState(defaultColors.secondaryColor), secondaryColorCandidate = _e[0], setSecondaryColorCandidate = _e[1];
    var _f = useState("TOP_LEFT"), position = _f[0], setPosition = _f[1];
    var fetchData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.clp.getConfig(businessId)];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 3:
                    res = res.data;
                    setCustomSlug(res.slug);
                    setPosition(res.position);
                    if (res === null || res === void 0 ? void 0 : res.clp_color_primary) {
                        setPrimaryColorCandidate(res === null || res === void 0 ? void 0 : res.clp_color_primary);
                    }
                    if (res === null || res === void 0 ? void 0 : res.clp_color_secondary) {
                        setSecondaryColorCandidate(res === null || res === void 0 ? void 0 : res.clp_color_secondary);
                    }
                    methods.reset(res);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId, methods]);
    useEffect(function () {
        if (!noTitle) {
            document.title = tolgee.t({
                key: "clp.manage_clp_title",
            });
        }
        fetchData();
    }, [fetchData, noTitle]);
    var handleUpdateSettings = function () { return __awaiter(_this, void 0, void 0, function () {
        var data, settings, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = methods.getValues();
                    settings = __assign(__assign({}, data), { position: position, clp_color_primary: primaryColorCandidate, clp_color_secondary: secondaryColorCandidate, clp_text: data.clp_text && data.clp_text.length > 0 ? data.clp_text.trim() : null });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.clp.updateBusinessConfig(businessId, settings)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [2 /*return*/];
                case 4:
                    dispatch(notify({
                        message: tolgee.t({
                            key: "clp.settings_updated",
                        }),
                        type: "SUCCESS",
                    }));
                    fetchData();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleUploadFile = function (file, type) { return __awaiter(_this, void 0, void 0, function () {
        var fileObject, _a, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (["imageCertificate", "logo"].includes(type) && !file.type.includes("image")) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.only_images_allowed",
                            }),
                            type: "ERROR",
                        }));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, fileToFileObject(file)];
                case 1:
                    fileObject = _b.sent();
                    _a = type;
                    switch (_a) {
                        case "logo": return [3 /*break*/, 2];
                        case "cookie": return [3 /*break*/, 3];
                        case "privacy": return [3 /*break*/, 4];
                        case "imageCertificate": return [3 /*break*/, 5];
                        case "backgroundImage": return [3 /*break*/, 9];
                    }
                    return [3 /*break*/, 10];
                case 2:
                    methods.setValue("logo", fileObject);
                    handleUpdateSettings();
                    return [3 /*break*/, 11];
                case 3:
                    methods.setValue("cookie_policy", fileObject);
                    handleUpdateSettings();
                    return [3 /*break*/, 11];
                case 4:
                    methods.setValue("privacy_policy", fileObject);
                    handleUpdateSettings();
                    return [3 /*break*/, 11];
                case 5:
                    _b.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, api.clp.addCertificateImage(businessId, fileObject)];
                case 6:
                    _b.sent();
                    return [3 /*break*/, 8];
                case 7:
                    e_3 = _b.sent();
                    handleError(e_3);
                    return [2 /*return*/];
                case 8:
                    fetchData();
                    return [3 /*break*/, 11];
                case 9:
                    methods.setValue("background_image", fileObject);
                    handleUpdateSettings();
                    return [3 /*break*/, 11];
                case 10: return [3 /*break*/, 11];
                case 11: return [2 /*return*/];
            }
        });
    }); };
    var handleRemoveFile = function (type, fileName) { return __awaiter(_this, void 0, void 0, function () {
        var e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    if (!(type === "CERTIFICATE_IMAGE")) return [3 /*break*/, 2];
                    return [4 /*yield*/, api.clp.deleteCertificateImage(businessId, fileName)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, api.business.deletePublicFile(businessId, type)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_4 = _a.sent();
                    handleError(e_4);
                    return [3 /*break*/, 6];
                case 6:
                    fetchData();
                    return [2 /*return*/];
            }
        });
    }); };
    var tabsData = [
        {
            id: 1,
            label: tolgee.t({ key: "clp.customize_lp" }),
            content: (_jsx(CustomizeLandingPage, { customSlug: customSlug, primaryColorCandidate: primaryColorCandidate, setPrimaryColorCandidate: setPrimaryColorCandidate, secondaryColorCandidate: secondaryColorCandidate, setSecondaryColorCandidate: setSecondaryColorCandidate, handleUploadFile: handleUploadFile, handleRemoveFile: handleRemoveFile, position: position, setPosition: setPosition, handleUpdateSettings: handleUpdateSettings })),
        },
        {
            id: 2,
            label: tolgee.t({ key: "clp.cookie_and_privacy_policy" }),
            content: (_jsx(CompanyPolicies, { handleUploadFile: handleUploadFile, handleRemoveFile: handleRemoveFile })),
        },
        {
            id: 3,
            label: tolgee.t({ key: "clp.upload_certificate" }),
            content: (_jsx(CertificationsPage, { handleUploadFile: handleUploadFile, handleRemoveFile: handleRemoveFile })),
        },
    ];
    return (_jsx(FormProvider, __assign({}, methods, { children: _jsxs("div", { className: "mx-auto max-w-7xl box-outerlayout", children: [_jsx("h1", { className: "text-2xl font-bold truncate", children: _jsx(T, { keyName: "clp.manage_clp" }) }), _jsx(BaseTabs, { tabsData: tabsData, defaultTab: 0 })] }) })));
}
