import { captureException, withScope } from "@sentry/react";
import { store } from "store";
import { notify, setError } from "store/app";
import { DEV_ENV } from "const/env";
import tolgee from "services/translation";
export var handleError = function (error, hideNotification, show404) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (hideNotification === void 0) { hideNotification = false; }
    if (show404 === void 0) { show404 = false; }
    if (["ERR_NETWORK", "ETIMEDOUT", "ECONNABORTED"].includes(error.code)) {
        store.dispatch(notify({
            type: "ERROR",
            message: "There seems to be a problem with your internet. Please refresh this page and try again.",
            duration: 5,
        }));
        return;
    }
    var reportSentry = true;
    if (show404 && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
        store.dispatch(setError("404"));
        reportSentry = false;
    }
    if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 401) {
        store.dispatch(setError("401"));
        reportSentry = false;
    }
    else if (((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 403) {
        store.dispatch(setError("403"));
        reportSentry = false;
    }
    else if ("".concat((_d = error.response) === null || _d === void 0 ? void 0 : _d.status).startsWith("5")) {
        store.dispatch(setError("500"));
    }
    if (!hideNotification) {
        if ("".concat((_e = error.response) === null || _e === void 0 ? void 0 : _e.status).startsWith("5")) {
            store.dispatch(notify({
                type: "ERROR",
                message: "We were not able to process your request (".concat((_f = error.response) === null || _f === void 0 ? void 0 : _f.status, " ").concat((_g = error.response) === null || _g === void 0 ? void 0 : _g.statusText, ")."),
                duration: 10,
            }));
        }
        else if (DEV_ENV) {
            store.dispatch(notify({
                type: "ERROR",
                message: "'".concat(error.config.method.toUpperCase(), " ").concat(error.config.url, "': ").concat(error.message),
                details: (_h = error.response) === null || _h === void 0 ? void 0 : _h.data,
                duration: 5,
            }));
        }
        else if (((_j = error.response) === null || _j === void 0 ? void 0 : _j.status) === 422) {
            store.dispatch(notify({
                type: "DEFAULT",
                message: "Action '".concat(error.config.method.toUpperCase(), " ").concat(error.config.url, "' failed, please refresh the page"),
                details: (_k = error.response) === null || _k === void 0 ? void 0 : _k.data,
                duration: 30,
            }));
        }
        else {
            store.dispatch(notify({
                type: "ERROR",
                message: tolgee.t({
                    key: "generic.smth_went_wrong",
                }),
            }));
        }
    }
    if (reportSentry) {
        withScope(function (scope) {
            var _a, _b, _c, _d, _e;
            // Add request details
            scope.setExtra("request", {
                method: (_a = error.config.method) === null || _a === void 0 ? void 0 : _a.toUpperCase(),
                url: "".concat(error.config.baseURL).concat(error.config.url),
                headers: error.config.headers,
                data: error.config.data,
                params: error.config.params,
            });
            // Add response details
            scope.setExtra("response", {
                status: (_b = error.response) === null || _b === void 0 ? void 0 : _b.status,
                statusText: (_c = error.response) === null || _c === void 0 ? void 0 : _c.statusText,
                data: (_d = error.response) === null || _d === void 0 ? void 0 : _d.data,
                headers: (_e = error.response) === null || _e === void 0 ? void 0 : _e.headers,
            });
            // Add error details
            scope.setExtra("error", {
                message: error.message,
                code: error.code,
                stack: error.stack,
            });
            captureException(error);
        });
    }
    // eslint-disable-next-line no-console
    console.error("There seems to be an error with the following API route: '".concat(error.config.method.toUpperCase(), " ").concat(error.config.baseURL).concat(error.config.url, "'. '").concat(error.message, "'"));
};
