var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import AddLinkIcon from "@mui/icons-material/AddLink";
import tolgee from "services/translation";
import { BaseButton, BaseModal, EditableText } from "components";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { api } from "services";
import { notify } from "store/app";
import { useAppDispatch } from "store";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck, reopenCheck } from "store/thunks";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { handleError } from "services/api/error";
export default function IncorrectSources() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var isFinished = isCheckFinished(state);
    var requestClosed = caseData.state === "CLOSED";
    var appDispatch = useAppDispatch();
    var _b = useState([]), incorrectSourceValues = _b[0], setIncorrectSourceValues = _b[1];
    var _c = useState([
        { id: "create_contact" },
    ]), incorrectSourceOptions = _c[0], setIncorrectSourceOptions = _c[1];
    var _d = useState([]), contactData = _d[0], setContactData = _d[1];
    var _e = useState(false), contactModal = _e[0], setContactModal = _e[1];
    var _f = useState(false), urlModal = _f[0], setUrlModal = _f[1];
    var _g = useState(""), search = _g[0], setSearch = _g[1];
    var _h = useState({ url: "", comment: "" }), urlContact = _h[0], setUrlContact = _h[1];
    var syncFetchedData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.contactDirectory.getContacts({
                            order_by: "person_name",
                            page: 1,
                            per_page: 100,
                            search_term: "",
                        })];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 3:
                    setContactData(res.data.result);
                    setIncorrectSourceOptions(__spreadArray([
                        { id: "create_contact" }
                    ], res.data.result.map(function (cont) { return ({
                        id: cont.uuid,
                        contact_id: cont.uuid,
                        url: "",
                        comment: "",
                    }); }), true));
                    return [2 /*return*/];
            }
        });
    }); }, []);
    useEffect(function () {
        function sync() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, syncFetchedData()];
                        case 1:
                            _a.sent();
                            setIncorrectSourceValues(caseData.outcome.incorrect_sources.map(function (source) { return (__assign({ id: source.contact_id || "url_contact_".concat(source.url) }, source)); }));
                            return [2 /*return*/];
                    }
                });
            });
        }
        sync();
    }, [syncFetchedData, caseData.outcome.incorrect_sources, state]);
    var generateLabel = function (option) {
        if (option === "create_contact") {
            return tolgee.t({
                key: generateI18nKey(caseData.type, state.ui.check, "create_contact"),
            });
        }
        if (option.startsWith("url_contact")) {
            return option.replace("url_contact_", "");
        }
        var contact = contactData.find(function (c) { return c.uuid === option; });
        var label = "".concat(contact.person_name, " ").concat(contact.organization_name ? " / ".concat(contact.organization_name) : "");
        var email = (contact === null || contact === void 0 ? void 0 : contact.emails.length) ? "(".concat(contact === null || contact === void 0 ? void 0 : contact.emails[0], ")") : "";
        return "".concat(label, " ").concat(email);
    };
    var handleChangeController = function (_, __, reason, details) {
        if (details.option.id === "create_contact") {
            setContactModal(true);
            setSearch("");
            return;
        }
        if (reason === "selectOption") {
            setIncorrectSourceValues(function (prevSources) { return __spreadArray(__spreadArray([], prevSources, true), [details.option], false); });
        }
        else if (reason === "removeOption") {
            handleRemoveIncorrectSource(details.option.id);
        }
        setSearch("");
    };
    var handleUpdateIncorrectSource = function (id, type, value) {
        setIncorrectSourceValues(function (prevSources) {
            return prevSources.map(function (source) {
                var _a;
                if (source.id === id) {
                    return __assign(__assign({}, source), (_a = {}, _a[type] = value, _a));
                }
                return source;
            });
        });
    };
    var handleRemoveIncorrectSource = function (id) {
        setIncorrectSourceValues(function (prevSources) { return prevSources.filter(function (source) { return source.id !== id; }); });
    };
    var handleCloseModal = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSearch("");
            syncFetchedData();
            setContactModal(false);
            return [2 /*return*/];
        });
    }); };
    var handleCompleteIncorrectSources = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.ccpa.updateIncorrectSources(caseData.uuid, incorrectSourceValues)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [2 /*return*/];
                case 3:
                    appDispatch(notify({
                        message: tolgee.t({
                            key: "generic.success",
                        }),
                        type: "SUCCESS",
                    }));
                    finalizeCheck()(dispatch, caseData.uuid, state.ui.check);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAddUrlContact = function () {
        var tempContact = __assign(__assign({}, urlContact), { id: "url_contact_".concat(urlContact.url), contact_id: null });
        setIncorrectSourceValues(function (prevSources) { return __spreadArray(__spreadArray([], prevSources, true), [tempContact], false); });
        setUrlContact({ url: "", comment: "" });
        setUrlModal(false);
    };
    var handleParentSubmit = function (contact) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, syncFetchedData()];
                case 1:
                    _a.sent();
                    setIncorrectSourceValues(function (prevSources) { return __spreadArray(__spreadArray([], prevSources, true), [
                        {
                            id: contact.uuid,
                            contact_id: contact.uuid,
                            url: "",
                            comment: "",
                        },
                    ], false); });
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("h3", { className: "mt-0", children: tolgee.t({
                    key: generateI18nKey(caseData.type, state.ui.check, "title"),
                }) }), tolgee.t({
                key: generateI18nKey(caseData.type, state.ui.check, "description"),
            }), _jsx(Autocomplete, { value: incorrectSourceValues, options: incorrectSourceOptions, disabled: isFinished && !requestClosed, getOptionLabel: function (option) { return generateLabel(option.id); }, renderTags: function () { return _jsx(_Fragment, {}); }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: tolgee.t({
                        key: generateI18nKey(caseData.type, state.ui.check, "create_controller"),
                    }), variant: "outlined" }))); }, onChange: handleChangeController, inputValue: search, onInputChange: function (_, newSearch) {
                    setSearch(newSearch);
                }, isOptionEqualToValue: function (option, value) { return option.id === value.id; }, disableClearable: true, multiple: true }), _jsx("div", { className: "bg-[#f1f5f9] p-2 rounded-md rounded-tr-none rounded-tl-none", children: _jsx(Button, { variant: "contained", startIcon: _jsx(AddLinkIcon, {}), onClick: function () { return setUrlModal(true); }, disabled: isFinished && !requestClosed, children: tolgee.t({
                        key: generateI18nKey(caseData.type, state.ui.check, "add_url_without_contact"),
                    }) }) }), _jsx("div", { className: "flex flex-row flex-wrap gap-4 mt-4", children: incorrectSourceValues.map(function (incorrectSource) { return (_jsxs("div", { children: [_jsxs("div", { className: "bg-[#f1f5f9] p-2 rounded-md rounded-bl-none flex items-center justify-between", children: [generateLabel(incorrectSource.id), _jsx(IconButton, { disabled: isFinished && !requestClosed, onClick: function () { return handleRemoveIncorrectSource(incorrectSource.id); }, children: _jsx(FontAwesomeIcon, { icon: "circle-xmark", size: "sm" }) })] }), !incorrectSource.id.startsWith("url_contact_") && (_jsxs("div", { className: "bg-[#f8fafc] mr-2 p-1 pl-2 flex flex-row items-center", children: [_jsxs("b", { children: [tolgee.t({
                                            key: generateI18nKey(caseData.type, state.ui.check, "url"),
                                        }), ":", " "] }), _jsx(EditableText, { disabled: isFinished && !requestClosed, value: incorrectSource.url, confirmation: false, onChange: function (text) {
                                        return handleUpdateIncorrectSource(incorrectSource.id, "url", text);
                                    } })] })), _jsxs("div", { className: "bg-[#f8fafc] mr-2 p-1 pl-2 flex flex-row items-center", children: [_jsxs("b", { children: [tolgee.t({
                                            key: "generic.comment",
                                        }), ":", " "] }), _jsx(EditableText, { disabled: isFinished && !requestClosed, value: incorrectSource.comment, confirmation: false, onChange: function (text) {
                                        return handleUpdateIncorrectSource(incorrectSource.id, "comment", text);
                                    } })] })] })); }) }), _jsxs("div", { className: "space-x-2 mt-4 flex justify-end", children: [isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), children: tolgee.t({
                            key: "general.reopen",
                        }) })), _jsx(BaseButton, { disabled: isFinished, onClick: handleCompleteIncorrectSources, color: "success", testid: "incorrectSources_confirm", children: tolgee.t({
                            key: generateI18nKey(caseData.type, state.ui.check, incorrectSourceValues.length > 0 ? "confirm" : "confirm_without_contact"),
                        }) })] }), _jsx(ContactModal, { modalState: [contactModal, handleCloseModal], emailRequired: true, parentSubmit: handleParentSubmit }), _jsx(BaseModal, { modalState: [urlModal, setUrlModal], children: _jsxs("div", { className: "flex flex-col space-y-2 min-w-[40rem]", children: [_jsx("h3", { className: "m-0", children: tolgee.t({
                                key: generateI18nKey(caseData.type, state.ui.check, "url_modal.title"),
                            }) }), _jsx(TextField, { label: tolgee.t({
                                key: generateI18nKey(caseData.type, state.ui.check, "url"),
                            }), value: urlContact.url, onChange: function (event) {
                                return setUrlContact(function (p) { return ({
                                    url: event.target.value,
                                    comment: p.comment,
                                }); });
                            } }), _jsx(TextField, { label: tolgee.t({
                                key: "generic.comment",
                            }), value: urlContact.comment, onChange: function (event) {
                                return setUrlContact(function (p) { return ({
                                    comment: event.target.value,
                                    url: p.url,
                                }); });
                            } }), _jsx(BaseButton, { className: "self-end", onClick: handleAddUrlContact, children: tolgee.t({
                                key: generateI18nKey(caseData.type, state.ui.check, "url_modal.submit"),
                            }) })] }) })] }));
}
