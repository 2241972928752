var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, MenuItem, Select, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { handleError } from "services/api/error";
var permissionOptions = ["read", "write", "admin"];
export default function Permissions() {
    var _this = this;
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var _a = useState(), data = _a[0], setData = _a[1];
    var _b = useState(), permissions = _b[0], setPermissions = _b[1];
    var fetchData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.business.get(businessId)];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 3:
                    setData(res.data);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId]);
    var getPermissions = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.security.getBusinessPermissions(businessId)];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [2 /*return*/];
                case 3:
                    setPermissions(res.data);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId]);
    useEffect(function () {
        document.title = tolgee.t({
            key: "permissions.manage_permissions",
        });
        fetchData();
        getPermissions();
    }, [fetchData, getPermissions]);
    var handlePermissionDelete = function (permissionData) { return __awaiter(_this, void 0, void 0, function () {
        var type, email, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    type = permissionData.type;
                    email = permissionData.user.email;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.security.deletePermissions(businessId, type, email)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    handleError(e_3);
                    return [2 /*return*/];
                case 4:
                    dispatch(notify({
                        message: tolgee.t({
                            key: "permissions.permission_deleted",
                        }),
                        type: "SUCCESS",
                    }));
                    getPermissions();
                    return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = function (newPermission) { return __awaiter(_this, void 0, void 0, function () {
        var currentPermission, permissionData, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentPermission = newPermission.permission || "admin";
                    permissionData = {
                        type: currentPermission.toUpperCase(),
                        email: newPermission.email,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.security.addPermissions(businessId, permissionData)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _a.sent();
                    handleError(e_4);
                    return [2 /*return*/];
                case 4:
                    dispatch(notify({
                        message: tolgee.t({
                            key: "permissions.permission_added",
                        }),
                        type: "SUCCESS",
                    }));
                    getPermissions();
                    setValue("email", null);
                    return [2 /*return*/];
            }
        });
    }); };
    var _c = useForm({
        defaultValues: { email: "", permission: "admin" },
    }), register = _c.register, setValue = _c.setValue, handleSubmit = _c.handleSubmit, watch = _c.watch;
    var permissionData = watch("permission");
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", children: [_jsxs("div", { className: "box-outerlayout mb-4", children: [_jsxs("h2", { children: [_jsx(T, { keyName: "permissions.manage_permissions_of" }), data === null || data === void 0 ? void 0 : data.company_name, " (", data === null || data === void 0 ? void 0 : data.public_id, ")"] }), _jsxs("div", { children: [_jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "permissions.you_can_onboard" }) }), _jsxs("ul", { className: "space-y-4", children: [_jsxs("li", { className: "list-disc", children: [_jsx("div", { className: "font-bold", children: _jsx(T, { keyName: "permissions.read" }) }), _jsx("div", { children: _jsx(T, { keyName: "permissions.read_perm" }) })] }), _jsxs("li", { className: "list-disc", children: [_jsx("div", { className: "font-bold", children: _jsx(T, { keyName: "permissions.write" }) }), _jsx("div", { children: _jsx(T, { keyName: "permissions.write_perm" }) })] }), _jsxs("li", { className: "list-disc", children: [_jsx("div", { className: "font-bold", children: _jsx(T, { keyName: "permissions.admin" }) }), _jsx("div", { children: _jsx(T, { keyName: "permissions.admin_perm" }) })] })] })] }), _jsxs("h3", { children: [_jsx(T, { keyName: "permissions.grant_permission" }), ":"] }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: "mt-4", children: [_jsxs("div", { className: "font-bold", children: [_jsx(T, { keyName: "permissions.permission" }), " *"] }), _jsx(Select, { defaultValue: "admin", required: true, id: "select", className: "w-full mt-4", value: permissionData, onChange: function (event) {
                                            var _a;
                                            setValue("permission", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value);
                                        }, children: permissionOptions.map(function (i) { return (_jsx(MenuItem, { value: i, children: _jsx(T, { keyName: "permissions.".concat(i) }) }, i)); }) }), _jsxs("div", { className: "font-bold mt-4", children: [_jsx(T, { keyName: "permissions.email" }), " *"] }), _jsx(TextField, __assign({ required: true, className: "w-full" }, register("email", {
                                        required: true,
                                    })))] }), _jsx("div", { className: "flex justify-end mt-6", children: _jsx(Button, { type: "submit", children: _jsx(T, { keyName: "generic.submit" }) }) })] })] }), _jsxs("div", { className: "box-outerlayout mb-4", children: [_jsx("h2", { children: _jsx(T, { keyName: "permissions.existing_permission" }) }), permissions && permissions.length > 0 && (_jsx("div", { children: permissions.map(function (i) { return (_jsxs("div", { className: "flex justify-between", children: [_jsx("div", { children: "".concat(i.user.email, " has the permission ").concat(i.type) }), _jsx("div", { children: _jsx(IconButton, { size: "small", onClick: function () { return handlePermissionDelete(i); }, children: _jsx(FontAwesomeIcon, { icon: "trash" }) }) })] }, i.uuid)); }) }))] })] }));
}
