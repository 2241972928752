import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { T } from "@tolgee/react";
import { useParams } from "react-router";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { BaseTextField } from "components/Input";
import { ccpaPrivacyPolicySnippet, optOutLink } from "const/ccpa";
export default function PrivacyPolicySnippet() {
    var businessId = useParams().businessId;
    var htmlSnippet = ccpaPrivacyPolicySnippet(optOutLink(businessId));
    var sanitizedHtml = DOMPurify.sanitize(htmlSnippet);
    return (_jsxs("div", { children: [_jsx("h2", { children: _jsx(T, { keyName: "ccpa.policy_snippet.title" }) }), _jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "ccpa.policy_snippet.description" }) }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), _jsxs("div", { children: [_jsx("h4", { className: "mb-4", children: _jsx(T, { keyName: "ccpa.policy_snippet.preview" }) }), _jsx("div", { children: parse(sanitizedHtml) })] }), _jsxs("div", { className: "border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full", children: [_jsx("div", { className: "mb-6", children: _jsx(BaseTextField, { containerClassName: "w-full", multiline: true, disabled: true, value: htmlSnippet }) }), _jsx("div", { className: "flex justify-end mt-4", children: _jsxs(Button, { onClick: function () { var _a; return (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(htmlSnippet); }, children: [_jsx(FontAwesomeIcon, { className: "mr-2", icon: "copy", color: "gray", size: "lg" }), _jsx(T, { keyName: "ccpa.copy" })] }) })] })] }));
}
