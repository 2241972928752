var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { productDict } from "const";
import { BaseModal, FormFileField, SplitButton } from "components";
import { fileToFileObject, getUrl } from "helpers";
import tolgee from "services/translation";
import { api } from "services";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import { handleError } from "services/api/error";
var filePrivateTypeDict = {
    art27: "EU_LOA",
    ukrep: "UK_LOA",
    niseu: "NIS_EU_LOA",
    nisuk: "NIS_UK_LOA",
    turkeyrep: "TURKEY_REP_LOA",
    swissrep: "SWISS_REP_LOA",
    ccpa: "CCPA_LOA",
    dsr: null,
    prighterbreach: null,
    "dsr-archival": null,
};
export default function RepresentativesTab(_a) {
    var _this = this;
    var business = _a.business;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(), uploadFileType = _c[0], setUploadFileType = _c[1];
    var _d = useState({}), loaFileUrls = _d[0], setLoaFileUrls = _d[1];
    var dispatch = useAppDispatch();
    useEffect(function () {
        var fetchLoaFiles = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, api.business.getLoaFileUrls(business.public_id)];
                    case 1:
                        res = _a.sent();
                        setLoaFileUrls(res.data);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        handleError(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchLoaFiles();
    }, [business.public_id]);
    var splitItems = function (emptyLoaUrl, fileType, loaUrl) {
        var items = [
            loaUrl
                ? {
                    name: tolgee.t({ key: "representatives.download_loa" }),
                    icon: "download",
                    action: function () { return window.open(loaUrl); },
                }
                : null,
            {
                name: tolgee.t({ key: "representatives.upload_loa" }),
                icon: "upload",
                action: function () {
                    setUploadFileType(fileType);
                    setOpen(true);
                },
            },
            {
                name: tolgee.t({ key: "representatives.download_blank_loa" }),
                icon: "download",
                action: function () { return window.open(emptyLoaUrl); },
            },
        ];
        return items.filter(function (i) { return i; });
    };
    var genericSplitItems = [
        {
            name: "Download blank LOA (combined EU-GDPR-Rep, UK-GDPR-Rep)",
            icon: "download",
            action: function () {
                return window.open(getUrl("FLASK", "/my/company/".concat(business.public_id, "/create-loa-template/pdf/ukrep+art27")));
            },
        },
        {
            name: "Download blank LOA (combined NISUK, NISEU)",
            icon: "upload",
            action: function () {
                return window.open(getUrl("FLASK", "/my/company/".concat(business.public_id, "/create-loa-template/pdf/nisuk+niseu")));
            },
        },
    ];
    var handleUploadFile = function (file, type) { return __awaiter(_this, void 0, void 0, function () {
        var fileObject, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fileToFileObject(file)];
                case 1:
                    fileObject = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, api.business.uploadFile(business.public_id, fileObject, type)];
                case 3:
                    _a.sent();
                    dispatch(notify({
                        message: tolgee.t({
                            key: "file_uploaded",
                        }),
                        type: "SUCCESS",
                    }));
                    return [3 /*break*/, 6];
                case 4:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [3 /*break*/, 6];
                case 5:
                    setOpen(false);
                    setUploadFileType(null);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "flex flex-col space-y-4 ", children: business.features
                    .filter(function (i) {
                    return Object.keys(productDict).includes(i) &&
                        !["prighterbreach", "dsr", "dsr-archival"].includes(i);
                })
                    .map(function (feature) {
                    var product = productDict[feature];
                    var emptyLoaUrl = getUrl("FLASK", "/my/company/".concat(business.public_id, "/create-loa-template/pdf/").concat(feature));
                    var fileKey = filePrivateTypeDict[feature];
                    var loaUrl = Object.keys(loaFileUrls).includes(feature)
                        ? loaFileUrls[feature]
                        : null;
                    return (_jsxs("div", { className: "flex items-center text-center justify-between space-x-4 border-b-2 border-gray-400 pb-1", children: [_jsxs("div", { className: "flex space-x-4", children: [_jsx("img", { src: product.certificateUrl(business.public_id), alt: "certificate", width: 92 }), _jsx("span", { className: "self-center", children: product.name })] }), _jsx("div", { children: _jsx(SplitButton, { items: splitItems(emptyLoaUrl, fileKey, loaUrl), listHeight: "424px", state: [0, function () { }] }) })] }));
                }) }), _jsx("div", { className: "self-end mt-6", children: _jsx(SplitButton, { items: genericSplitItems, listHeight: "424px", state: [0, function () { }] }) }), _jsxs(BaseModal, { modalState: [open, setOpen], children: [_jsxs("h3", { children: ["Upload ", uploadFileType && uploadFileType.replaceAll("_", " ")] }), _jsx(FormFileField, { label: tolgee.t({
                            key: "generic.choose_files",
                        }), onChange: function (files) { return handleUploadFile(files[0], uploadFileType); } })] })] }));
}
