var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popover, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch } from "react-redux";
import { useState } from "react";
import moment from "moment";
import { getBeautyDate } from "helpers";
import { getDaysLeftString } from "helpers/date";
import tolgee from "services/translation";
import { notify } from "store/app";
import { api } from "services";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
import { CaseTagNameEnum } from "types/case";
import { generateI18nKeyByCaseType } from "helpers/case";
import { handleError } from "services/api/error";
export default function DeadlineMenu() {
    var _this = this;
    var _a;
    var _b = useCase(), state = _b[0], dispatch = _b[1];
    var appDispatch = useDispatch();
    var _c = useState(), deadlineValue = _c[0], setDeadlineValue = _c[1];
    var _d = useState(true), disableDeadline = _d[0], setDisableDeadline = _d[1];
    var _e = useState(null), anchorEl = _e[0], setAnchorEl = _e[1];
    var _f = useState(""), reason = _f[0], setReason = _f[1];
    var caseData = state.case;
    var deadline = caseData.deadline, type = caseData.type;
    var requestClosed = caseData.state === "CLOSED";
    var FADPDisabled = requestClosed || !deadlineValue || disableDeadline;
    var extended = !!((_a = caseData.tags) === null || _a === void 0 ? void 0 : _a.find(function (i) { return (i === null || i === void 0 ? void 0 : i.name) === CaseTagNameEnum.DEADLINE_EXTENDED; }));
    var isFADP = type === null || type === void 0 ? void 0 : type.includes("FADP");
    var open = Boolean(anchorEl);
    var id = open ? "deadline-popover" : undefined;
    var handleExtendDeadline = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.authCase.extendDeadline(caseData.uuid, {
                            deadline: isFADP ? deadlineValue.toISOString() : null,
                            reason: reason,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 3:
                    appDispatch(notify({
                        message: tolgee.t({
                            key: generateI18nKeyByCaseType(caseData.type, "deadline_extended"),
                        }),
                        type: "SUCCESS",
                    }));
                    setReason("");
                    setAnchorEl(null);
                    fetchCaseData()(dispatch, caseData.uuid);
                    return [2 /*return*/];
            }
        });
    }); };
    var checkDeadlineValue = function (val) {
        if ((val === null || val === void 0 ? void 0 : val.isValid()) && val.isAfter(deadline)) {
            setDeadlineValue(val);
            setDisableDeadline(false);
        }
        else {
            setDisableDeadline(true);
        }
    };
    return (deadline && (_jsxs(_Fragment, { children: [_jsx("div", { className: "flex items-center", children: _jsxs(Button, { "aria-describedby": id, onClick: function (event) { return setAnchorEl(event.currentTarget); }, children: [_jsxs("span", { className: "hidden @xl:block", children: [getDaysLeftString(deadline), " ", tolgee.t({
                                    key: generateI18nKeyByCaseType(caseData.type, "deadline_left"),
                                })] }), _jsx("span", { className: "block @xl:hidden", children: _jsx(FontAwesomeIcon, { icon: "calendar-xmark", size: "1x" }) }), _jsx(FontAwesomeIcon, { className: "ml-2", icon: open ? "angle-up" : "angle-down" })] }) }), _jsx(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: function () { return setAnchorEl(null); }, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                }, children: _jsxs("div", { className: "flex flex-col p-4 space-y-4 w-96", children: [_jsx("h3", { children: tolgee.t({
                                key: generateI18nKeyByCaseType(caseData.type, "extend_deadline_title"),
                            }) }), _jsx("div", { children: tolgee.t({
                                key: generateI18nKeyByCaseType(caseData.type, "extend_deadline_description"),
                            }) }), !isFADP && (_jsxs("div", { className: "flex space-x-2", children: [_jsxs("b", { children: [tolgee.t({
                                            key: generateI18nKeyByCaseType(caseData.type, "current_deadline"),
                                        }), ":"] }), _jsx("div", { children: getBeautyDate(deadline, true) })] })), isFADP && !extended && (_jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, { minDate: moment.utc(deadline).add(1, "day"), format: "DD/MM/YYYY", className: "w-full", label: "Deadline", value: deadlineValue, onChange: function (newValue) { return checkDeadlineValue(newValue); } }) })), !extended &&
                            caseData.type !== "DSR_CCPA_OPTOUT" &&
                            caseData.type !== "DSR_CCPA_LIMIT" && (_jsxs("div", { className: "flex flex-col space-y-4", children: [_jsx(TextField, { label: tolgee.t({
                                        key: generateI18nKeyByCaseType(caseData.type, "extend_deadline_reason"),
                                    }), value: reason, onChange: function (event) { return setReason(event.target.value); }, required: true, disabled: isFADP ? FADPDisabled : requestClosed }), _jsx(Button, { onClick: handleExtendDeadline, disabled: !reason || isFADP ? FADPDisabled : requestClosed, children: tolgee.t({
                                        key: generateI18nKeyByCaseType(caseData.type, "extend_deadline_button"),
                                    }) })] }))] }) })] })));
}
