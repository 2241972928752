import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import ClosedCase from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/HandlingReview/ClosedCase";
import OpenCase from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/HandlingReview/OpenCase";
import { useCase } from "pages/Client/Case/CaseContext";
export default function HandlingReview() {
    var state = useCase()[0];
    var caseData = state.case;
    var requestClosed = caseData.state === "CLOSED";
    return _jsx(_Fragment, { children: requestClosed ? _jsx(ClosedCase, {}) : _jsx(OpenCase, {}) });
}
