export var ExemptionGroup;
(function (ExemptionGroup) {
    ExemptionGroup["EXEMPTIONS_OBLIGATION_CCPA_ACCESS"] = "EXEMPTIONS_OBLIGATION_CCPA_ACCESS";
    ExemptionGroup["EXEMPTIONS_OBLIGATION_CCPA_ACCESS_CATEGORIES"] = "EXEMPTIONS_OBLIGATION_CCPA_ACCESS_CATEGORIES";
    ExemptionGroup["EXEMPTIONS_OBLIGATION_CCPA_CORRECT"] = "EXEMPTIONS_OBLIGATION_CCPA_CORRECT";
    ExemptionGroup["EXEMPTIONS_OBLIGATION_CCPA_DELETE"] = "EXEMPTIONS_OBLIGATION_CCPA_DELETE";
    ExemptionGroup["EXEMPTIONS_OBLIGATION_CCPA_LIMIT"] = "EXEMPTIONS_OBLIGATION_CCPA_LIMIT";
    ExemptionGroup["EXEMPTIONS_OBLIGATION_CCPA_OPTOUT"] = "EXEMPTIONS_OBLIGATION_CCPA_OPTOUT";
    ExemptionGroup["EXEMPTIONS_OPTIONAL_CCPA_ACCESS"] = "EXEMPTIONS_OPTIONAL_CCPA_ACCESS";
    ExemptionGroup["EXEMPTIONS_OPTIONAL_CCPA_ACCESS_CATEGORIES"] = "EXEMPTIONS_OPTIONAL_CCPA_ACCESS_CATEGORIES";
    ExemptionGroup["EXEMPTIONS_OPTIONAL_CCPA_CORRECT"] = "EXEMPTIONS_OPTIONAL_CCPA_CORRECT";
    ExemptionGroup["EXEMPTIONS_OPTIONAL_CCPA_DELETE"] = "EXEMPTIONS_OPTIONAL_CCPA_DELETE";
    ExemptionGroup["EXEMPTIONS_OPTIONAL_CCPA_LIMIT"] = "EXEMPTIONS_OPTIONAL_CCPA_LIMIT";
    ExemptionGroup["EXEMPTIONS_OPTIONAL_CCPA_OPTOUT"] = "EXEMPTIONS_OPTIONAL_CCPA_OPTOUT";
})(ExemptionGroup || (ExemptionGroup = {}));
export var ClosedAsEnum;
(function (ClosedAsEnum) {
    ClosedAsEnum["DSR_CREATED"] = "DSR_CREATED";
    ClosedAsEnum["DSR_HANDLED_COMPLIED"] = "DSR_HANDLED_COMPLIED";
    ClosedAsEnum["DSR_HANDLED_DECLINED"] = "DSR_HANDLED_DECLINED";
    ClosedAsEnum["DSR_HANDLED_PARTIALLY_COMPLIED"] = "DSR_HANDLED_PARTIALLY_COMPLIED";
    ClosedAsEnum["DSR_IDENTIFICATION_FAILED"] = "DSR_IDENTIFICATION_FAILED";
    ClosedAsEnum["DSR_LOCATION_FAILED"] = "DSR_LOCATION_FAILED";
    ClosedAsEnum["DSR_NOT_PRIVACY_RELATED"] = "DSR_NOT_PRIVACY_RELATED";
    ClosedAsEnum["DSR_PROCESSOR"] = "DSR_PROCESSOR";
    ClosedAsEnum["EMAIL_FORWARDED"] = "EMAIL_FORWARDED";
    ClosedAsEnum["EMAIL_NOT_UNDERSTOOD"] = "EMAIL_NOT_UNDERSTOOD";
    ClosedAsEnum["GENERIC_DUPLICATE"] = "GENERIC_DUPLICATE";
    ClosedAsEnum["GENERIC_SILENTLY"] = "GENERIC_SILENTLY";
    ClosedAsEnum["GENERIC_SPAM"] = "GENERIC_SPAM";
    ClosedAsEnum["GENERIC_TEST"] = "GENERIC_TEST";
})(ClosedAsEnum || (ClosedAsEnum = {}));
