var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { T } from "@tolgee/react";
import { debounce } from "lodash";
import DefaultDataPoints from "components/DSRSettings/CCPA/DefaultDataPoints";
import PrivacyPolicyDsr from "components/DSRSettings/CCPA/PrivacyPolicyDsr";
import EnableDocumentUpload from "components/DSRSettings/CCPA/EnableDocumentUpload";
import BetaProgram from "components/DSRSettings/CCPA/BetaProgram";
import { api } from "services";
import tolgee from "services/translation";
import { defaultDataPoints } from "const/ccpa";
import { DefaultPointType } from "types/ccpaSettings";
import PrivacyPolicySnippet from "components/DSRSettings/CCPA/PrivacyPolicySnippet";
import { handleError } from "services/api/error";
export default function CCPAView() {
    var _this = this;
    var isFirstRender = useRef(true);
    var businessId = useParams().businessId;
    var _a = useState({
        selectedItems: [],
        options: [],
    }), defaultPoints = _a[0], setDefaultPoints = _a[1];
    var _b = useState(false), privacyPolicyDsrEnabled = _b[0], setPrivacyPolicyDsrEnabled = _b[1];
    var _c = useState(false), requiredDocumentsUpload = _c[0], setRequiredDocumentsUpload = _c[1];
    var handleBackendCcpaSettings = useCallback(function (data) { return __awaiter(_this, void 0, void 0, function () {
        var formattedDefaultPointOptions;
        return __generator(this, function (_a) {
            formattedDefaultPointOptions = defaultDataPoints.map(function (item) { return ({
                key: item,
                value: tolgee.t("dsr.settings.ccpa.default_data.".concat(item)),
                selected: false,
            }); });
            setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { options: formattedDefaultPointOptions })); });
            handleDefaultPointsInitialValue(data, formattedDefaultPointOptions);
            // Privacy Policy
            setPrivacyPolicyDsrEnabled(data.send_privacy_policy || false);
            // Document Upload
            setRequiredDocumentsUpload(data.document_upload || false);
            return [2 /*return*/];
        });
    }); }, []);
    var fetchData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.ccpa.getSettings(businessId)];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 3:
                    handleBackendCcpaSettings(res.data);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId, handleBackendCcpaSettings]);
    useEffect(function () {
        fetchData();
    }, [businessId, fetchData]);
    var putDataToBackend = debounce(function (data) {
        try {
            api.ccpa.updateSettings(businessId, data);
        }
        catch (e) {
            handleError(e);
        }
    }, 500);
    useEffect(function () {
        if (isFirstRender.current) {
            // Skip the first render
            isFirstRender.current = false;
            return;
        }
        var payloadData = {
            required_inputs: defaultPoints.selectedItems
                .filter(function (dataItem) { return dataItem.type === "required_inputs" && dataItem.value; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            required_inputs_strict: defaultPoints.selectedItems
                .filter(function (dataItem) { return dataItem.type === "required_inputs_strict" && dataItem.value; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            additional_inputs: defaultPoints.selectedItems
                .filter(function (dataItem) { return dataItem.type === "additional_inputs" && dataItem.value; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            categories_collected: [],
            categories_shared: {},
            categories_disclosed: {},
            sell_share: false,
            disclose: false,
            collect: false,
            document_upload: requiredDocumentsUpload,
            send_privacy_policy: privacyPolicyDsrEnabled,
        };
        putDataToBackend(payloadData);
    }, [
        defaultPoints.selectedItems,
        requiredDocumentsUpload,
        privacyPolicyDsrEnabled,
        putDataToBackend,
    ]);
    var handleDefaultPointsInitialValue = function (data, options) {
        var localOptions = options;
        var requiredInputs = data.required_inputs;
        var requiredInputsStrict = data.required_inputs_strict;
        var additionalInputs = data.additional_inputs;
        var items = [];
        var _loop_1 = function (i) {
            var item = {
                id: i,
                key: localOptions.find(function (formattedItem) { return formattedItem.key === requiredInputs[i]; }).key,
                value: localOptions.find(function (formattedItem) { return formattedItem.key === requiredInputs[i]; }).value,
                type: DefaultPointType.required_inputs,
            };
            localOptions.find(function (formattedItem) { return formattedItem.key === requiredInputs[i]; }).selected = true;
            items.push(item);
        };
        for (var i = 0; i < requiredInputs.length; i += 1) {
            _loop_1(i);
        }
        if (requiredInputsStrict) {
            var _loop_2 = function (i) {
                var strictItem = {
                    id: items.length + 1,
                    key: localOptions.find(function (formattedItem) { return formattedItem.key === requiredInputsStrict[i]; })
                        .key,
                    value: localOptions.find(function (item) { return item.key === requiredInputsStrict[i]; }).value,
                    type: DefaultPointType.required_inputs_strict,
                };
                localOptions.find(function (item) { return item.key === requiredInputsStrict[0]; }).selected = true;
                items.push(strictItem);
            };
            for (var i = 0; i < requiredInputsStrict.length; i += 1) {
                _loop_2(i);
            }
        }
        if (additionalInputs) {
            var _loop_3 = function (i) {
                var additionalItem = {
                    id: items.length + 1,
                    key: localOptions.find(function (formattedItem) { return formattedItem.key === additionalInputs[i]; }).key,
                    value: localOptions.find(function (item) { return item.key === additionalInputs[i]; }).value,
                    type: DefaultPointType.additional_inputs,
                };
                localOptions.find(function (item) { return item.key === additionalInputs[i]; }).selected = true;
                items.push(additionalItem);
            };
            for (var i = 0; i < additionalInputs.length; i += 1) {
                _loop_3(i);
            }
        }
        setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: items, options: localOptions })); });
    };
    var handleDefaultPointSelect = function (updatedItem, updatedValue) {
        var updatedDataItems = defaultPoints.selectedItems.map(function (item) {
            return item.id === updatedItem.id
                ? __assign(__assign({}, item), { key: defaultPoints.options.find(function (dataItem) { return dataItem.value === updatedValue; }).key, value: updatedValue }) : item;
        });
        var updatedOptions = defaultPoints.options.map(function (item) {
            // Update selected one
            if (item.value === updatedValue) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            // Update previously selected one
            if (item.value === updatedItem.value) {
                return __assign(__assign({}, item), { selected: false });
            }
            // Rest of items just return
            return item;
        });
        setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedDataItems, options: updatedOptions })); });
    };
    var handleAdditionalPointDeletion = function (deletionItem) {
        var updatedItems = __spreadArray([], defaultPoints.selectedItems, true);
        var itemIndex = updatedItems.findIndex(function (item) { return item.id === deletionItem.id; });
        updatedItems.splice(itemIndex, 1);
        var updatedOptions = defaultPoints.options.map(function (item) {
            if (item.key === deletionItem.key) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            return item;
        });
        setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems, options: updatedOptions })); });
    };
    var addAdditionalEmptyPoint = function () {
        var item = {
            id: defaultPoints.selectedItems.length + 1,
            key: "",
            value: "",
            type: DefaultPointType.additional_inputs,
        };
        var newDataItems = __spreadArray([], defaultPoints.selectedItems, true);
        newDataItems.push(item);
        setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: newDataItems })); });
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "flex flex-col", children: _jsxs("div", { children: [_jsxs("div", { className: "mt-4 mb-4 box-outerlayout", children: [_jsx("h2", { children: _jsx(T, { keyName: "dsr.settings.ccpa.title" }) }), _jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.description" }) }), _jsx("h3", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.customization_heading" }) }), _jsxs("div", { className: "box-dotted mb-4", children: [_jsx("div", { children: _jsx(DefaultDataPoints, { defaultPointsData: defaultPoints.selectedItems, defaultPointsOptions: defaultPoints.options, handleSelection: handleDefaultPointSelect, handleDeletion: handleAdditionalPointDeletion, addAdditionalPoint: addAdditionalEmptyPoint }) }), _jsx("hr", { className: "h-px my-8 bg-gray-200 border-0" }), _jsx("div", { children: _jsx(EnableDocumentUpload, { isEnabled: requiredDocumentsUpload, handleEnabled: function (value) { return setRequiredDocumentsUpload(value); } }) })] }), _jsx("h3", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.process_customization_heading" }) }), _jsxs("div", { className: "box-dotted mb-4", children: [_jsx("div", { className: "mb-4", children: _jsx(PrivacyPolicyDsr, { businessId: businessId, isEnabled: privacyPolicyDsrEnabled, handleEnabled: function (value) {
                                                setPrivacyPolicyDsrEnabled(value);
                                            } }) }), _jsx("hr", { className: "h-px my-8 bg-gray-200 border-0" }), _jsx("div", { children: _jsx(PrivacyPolicySnippet, {}) })] })] }), _jsx(BetaProgram, {})] }) }) }));
}
