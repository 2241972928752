var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, IconButton } from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormFileField } from "components";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { fileToFileObject } from "helpers";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { generateFileUrl } from "helpers/case";
import { handleError } from "services/api/error";
export default function RopaPage() {
    var _this = this;
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var _a = useState(null), business = _a[0], setBusiness = _a[1];
    var _b = useState(), ropaController = _b[0], setRopaController = _b[1];
    var _c = useState(), ropaProcessor = _c[0], setRopaProcessor = _c[1];
    var fetchBusinessData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.business.get(businessId)];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 3:
                    setBusiness(res.data);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId]);
    var fetchRopaControllerFiles = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.business.getFile(businessId, "ROPA_CONTROLLER")];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleError(e_2, true);
                    return [2 /*return*/];
                case 3:
                    setRopaController(res.data);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId]);
    var fetchRopaProcessorFiles = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.business.getFile(businessId, "ROPA_PROCESSOR")];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    handleError(e_3, true);
                    return [2 /*return*/];
                case 3:
                    setRopaProcessor(res.data);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId]);
    var fetchRopaFiles = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            fetchRopaControllerFiles();
            fetchRopaProcessorFiles();
            return [2 /*return*/];
        });
    }); }, [fetchRopaControllerFiles, fetchRopaProcessorFiles]);
    var handleDataFetching = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchBusinessData()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, fetchRopaFiles()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [fetchBusinessData, fetchRopaFiles]);
    useEffect(function () {
        document.title = tolgee.t({
            key: "ropa.ropa",
        });
        handleDataFetching();
    }, [businessId, handleDataFetching]);
    var handleUploadFile = function (file, type) { return __awaiter(_this, void 0, void 0, function () {
        var fileObject, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fileToFileObject(file)];
                case 1:
                    fileObject = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, api.business.uploadFile(businessId, fileObject, type)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_4 = _a.sent();
                    handleError(e_4);
                    return [2 /*return*/];
                case 5:
                    dispatch(notify({
                        message: tolgee.t({
                            key: "file_uploaded",
                        }),
                        type: "SUCCESS",
                    }));
                    if (type === "ROPA_CONTROLLER") {
                        fetchRopaControllerFiles();
                    }
                    else {
                        fetchRopaProcessorFiles();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteFile = function (type) { return __awaiter(_this, void 0, void 0, function () {
        var e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.business.deleteFile(businessId, type)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    handleError(e_5);
                    return [2 /*return*/];
                case 3:
                    dispatch(notify({
                        message: tolgee.t({
                            key: "ropa.file_deleted",
                        }),
                        type: "SUCCESS",
                    }));
                    if (type === "ROPA_CONTROLLER") {
                        fetchRopaControllerFiles();
                    }
                    else {
                        fetchRopaProcessorFiles();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", children: [_jsxs("div", { className: "box-outerlayout mb-4", children: [_jsxs("h1", { children: [_jsx(T, { keyName: "ropa.ropa" }), " (", business === null || business === void 0 ? void 0 : business.company_name, ")"] }), _jsx("div", { className: "mb-4 mt-2", children: _jsx(T, { keyName: "ropa.ropa_description_part1" }) }), _jsx("div", { children: _jsx(T, { keyName: "ropa.ropa_description_part2" }) }), _jsx("div", { className: "mt-6", children: _jsx(Button, { onClick: null, children: tolgee.t({
                                key: "ropa.download",
                            }) }) })] }), _jsxs("div", { className: "box-outerlayout mb-4", children: [_jsx("h1", { children: _jsx(T, { keyName: "ropa.upload_records" }) }), _jsxs("div", { className: "flex space-x-6", children: [_jsxs("div", { className: "flex flex-1 flex-col", children: [_jsx("h3", { children: _jsx(T, { keyName: "ropa.ropa_contraller" }) }), _jsxs("div", { className: "flex flex-col gap-8", children: [_jsx(FormFileField, { label: "Upload a file", className: "self-center", onChange: function (files) {
                                                    return files.length ? handleUploadFile(files[0], "ROPA_CONTROLLER") : "";
                                                }, clearFilesAfterUpload: true, fullWidth: true }), ropaController ? (_jsxs("div", { className: "flex flex-row items-center gap-4", children: [_jsx(FileDownloadPreview, { url: generateFileUrl(businessId, "ROPA_CONTROLLER"), fileName: tolgee.t({ key: "file.ropa_controller" }) }), _jsx(IconButton, { size: "medium", onClick: function () { return handleDeleteFile("ROPA_CONTROLLER"); }, children: _jsx(FontAwesomeIcon, { icon: "xmark", className: "text-gray-600", size: "xs" }) })] })) : ("")] })] }), _jsxs("div", { className: "flex flex-1 flex-col", children: [_jsx("h3", { children: _jsx(T, { keyName: "ropa.ropa_processor" }) }), _jsxs("div", { className: "flex flex-col gap-8", children: [_jsx(FormFileField, { label: "Upload a file", className: "self-center", onChange: function (files) {
                                                    return files.length ? handleUploadFile(files[0], "ROPA_PROCESSOR") : "";
                                                }, clearFilesAfterUpload: true, fullWidth: true }), ropaProcessor ? (_jsxs("div", { className: "flex flex-row items-center gap-4", children: [_jsx(FileDownloadPreview, { url: generateFileUrl(businessId, "ROPA_PROCESSOR"), fileName: tolgee.t({ key: "file.ropa_controller" }) }), _jsx(IconButton, { size: "medium", onClick: function () { return handleDeleteFile("ROPA_PROCESSOR"); }, children: _jsx(FontAwesomeIcon, { icon: "xmark", className: "text-gray-600", size: "xs" }) })] })) : ("")] })] })] })] })] }));
}
