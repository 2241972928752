var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import clsx from "clsx";
import { api } from "services";
import tolgee from "services/translation";
import { productDict } from "const";
import { getFontColor } from "helpers/general";
import { LEGAL_INFO, PRIGHTER_URL, PRIVACY_POLICY } from "const/env";
import { getBeautyDate, getUrl } from "helpers";
import SkeletonLandingPage from "pages/Public/LandingPage/SkeletonView";
import EmptyLandingPage from "pages/Public/LandingPage/EmptyLandingPage";
import { BaseModal } from "components/DataDisplay/Modal";
import { handleError } from "services/api/error";
var logoPosition = {
    TOP_RIGHT: "top-0 right-4",
    BOTTOM_RIGHT: "bottom-4 right-4",
    TOP_LEFT: "top-0 left-4",
    BOTTOM_LEFT: "bottom-4 left-4",
    BOTTOM_CENTER: "bottom-4 left-1/2 transform -translate-x-1/2",
    TOP_CENTER: "top-4 left-1/2 transform -translate-x-1/2",
    CENTER_LEFT: "xl:top-1/2 left-4 transform top-0  xl:-translate-y-1/2",
    CENTER_RIGHT: "xl:top-1/2 right-4 transform top-0 xl:-translate-y-1/2",
};
var textColor = {
    light: "black",
    dark: "white",
};
var bgColor = {
    light: "rgb(255 255 255 / 50%)",
    dark: "rgb(0 0 0 / 50%)",
};
var adjustColor = function (color, amount) {
    return "#".concat(color
        .replace(/^#/, "")
        .replace(/../g, function (adjusted) {
        return "0".concat(Math.min(255, Math.max(0, parseInt(adjusted, 16) + amount)).toString(16)).substr(-2);
    }));
};
export default function LandingPage() {
    var _this = this;
    var _a, _b, _c;
    var publicIdOrSlug = useParams().publicIdOrSlug;
    var dispatch = useDispatch();
    var _d = useState(), settings = _d[0], setSettings = _d[1];
    var _e = useState({}), repLocations = _e[0], setRepLocations = _e[1];
    var textStyle = { color: textColor[(_b = (_a = settings === null || settings === void 0 ? void 0 : settings.config) === null || _a === void 0 ? void 0 : _a.theme) === null || _b === void 0 ? void 0 : _b.toLowerCase()] };
    var _f = useState(true), loading = _f[0], setLoading = _f[1];
    var _g = useState({}), openModals = _g[0], setOpenModals = _g[1];
    var toggleModal = function (locationKey) {
        setOpenModals(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[locationKey] = !prev[locationKey], _a)));
        });
    };
    useEffect(function () {
        document.title = tolgee.t({
            key: "public_clp.compliance_center",
        });
        var fetchSettings = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, e_1, resLocations, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, api.clp.getSettings(publicIdOrSlug)];
                    case 2:
                        res = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        handleError(e_1, true);
                        setLoading(false);
                        return [2 /*return*/];
                    case 4:
                        res = res.data;
                        try {
                            api.clp.visitPortal(publicIdOrSlug);
                        }
                        catch (e) {
                            handleError(e, true);
                        }
                        setSettings(res);
                        document.title = "".concat(res.config.trade_name || res.company_name, " | ").concat(tolgee.t({
                            key: "public_clp.compliance_center",
                        }));
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, 8, 9]);
                        return [4 /*yield*/, api.clp.getRepLocations(res.public_id)];
                    case 6:
                        resLocations = _a.sent();
                        return [3 /*break*/, 9];
                    case 7:
                        e_2 = _a.sent();
                        handleError(e_2, true);
                        return [2 /*return*/];
                    case 8:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 9:
                        setRepLocations(resLocations.data);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchSettings();
    }, [publicIdOrSlug, dispatch]);
    var getAdjustedPrimaryColorByTheme = function () {
        var _a;
        if (!((_a = settings === null || settings === void 0 ? void 0 : settings.config) === null || _a === void 0 ? void 0 : _a.clp_color_secondary)) {
            return undefined;
        }
        var theme = settings.config.theme.toLowerCase();
        if (theme === "dark") {
            return adjustColor(settings.config.clp_color_secondary, -100); // Darken for light theme
        }
        if (theme === "light") {
            return adjustColor(settings.config.clp_color_secondary, 100); // Lighten for dark theme
        }
        return settings.config.clp_color_secondary; // Default to original color if theme is undefined
    };
    var adjustedPrimaryColorByTheme = getAdjustedPrimaryColorByTheme();
    var companyName = ((_c = settings === null || settings === void 0 ? void 0 : settings.config) === null || _c === void 0 ? void 0 : _c.trade_name) || (settings === null || settings === void 0 ? void 0 : settings.company_name);
    return !loading ? (_jsx(_Fragment, { children: (settings === null || settings === void 0 ? void 0 : settings.public_id) && Object.keys(repLocations).length > 0 ? (_jsxs("div", { style: {
                backgroundColor: settings.config.clp_color_secondary,
                backgroundImage: "url(".concat(settings.config.clp_background_image_url, ")"),
                backgroundSize: "cover",
                height: "100%",
            }, className: "relative flex flex-col items-center justify-center min-h-screen px-2 mx-auto sm:px-4", children: [settings.config.logo_url && (_jsx("figure", { className: "mt-4 image fixed ".concat(logoPosition[settings.config.position]), children: _jsx("img", { alt: "company logo", style: { maxHeight: "80px", maxWidth: "250px" }, src: settings.config.logo_url }) })), _jsxs("div", { style: {
                        backgroundColor: bgColor[settings.config.theme.toLowerCase()],
                        borderColor: adjustedPrimaryColorByTheme,
                    }, className: clsx("flex flex-col w-full max-w-screen-lg p-6 m-4 shadow-2xl backdrop-blur-sm space-y-6 rounded-lg border pt-0", {
                        "mt-28 mb-12": settings.config.position.includes("TOP"),
                        "mt-28 xl:my-4 mb-12": settings.config.position.includes("CENTER_RIGHT") ||
                            settings.config.position.includes("CENTER_LEFT"),
                        "mb-28": settings.config.position.includes("BOTTOM"),
                    }), children: [_jsx("div", { className: "flex mt-6 space-x-1 text-2xl font-bold md:text-4xl", children: _jsxs("div", { style: textStyle, children: [companyName, " ", _jsx(T, { keyName: "public_clp.compliance_center" })] }) }), settings.config.clp_text && _jsx("div", { style: textStyle, children: settings.config.clp_text }), settings.config.dsr_form && (_jsx("div", { children: _jsx(Button, { component: Link, to: "/dsr/".concat(settings.public_id), size: "large", style: {
                                    backgroundColor: settings.config.clp_color_primary,
                                }, className: "flex self-start space-x-1", children: _jsxs("div", { className: "font-bold", style: { color: getFontColor(settings.config.clp_color_primary) }, children: [_jsx(T, { keyName: "public_clp.privacy_request" }), " ", companyName] }) }) })), _jsx("div", { className: "flex space-x-1 text-xs", children: _jsxs("div", { style: textStyle, children: [companyName, " ", _jsx(T, { keyName: "public_clp.collects_ip" })] }) }), _jsxs("div", { style: __assign(__assign({}, textStyle), { display: "inline" }), children: [_jsx("b", { children: _jsx(T, { keyName: "public_clp.prighter_group" }) }), " ", _jsx(T, { keyName: "public_clp.is_representative" }), " ", _jsx("b", { children: companyName }), " ", _jsx(T, { keyName: "public_clp.following_jusrisdictions" })] }), _jsx("div", { className: "flex flex-wrap gap-8", children: repLocations &&
                                Object.keys(repLocations).map(function (i) {
                                    var _a, _b, _c, _d, _e;
                                    var productKey = i;
                                    return (_jsxs(React.Fragment, { children: [_jsxs("button", { type: "button", onClick: function () { return toggleModal(i); }, className: "cursor-pointer flex flex-col space-y-14 w-[110px] items-center", children: [_jsx("div", { className: "h-14 w-14", children: _jsx("img", { src: (_a = productDict[productKey]) === null || _a === void 0 ? void 0 : _a.image, alt: i }) }), _jsx("div", { className: "mt-2 text-xs font-bold", style: textStyle, children: (_b = productDict[productKey]) === null || _b === void 0 ? void 0 : _b.name })] }), _jsxs(BaseModal, { modalState: [openModals[i] || false, function () { return toggleModal(i); }], className: "max-w-[30rem] w-full max-h-150 overflow-auto", children: [_jsxs("div", { className: "mb-4", children: [_jsx("h3", { className: "mt-0", children: (_c = productDict[productKey]) === null || _c === void 0 ? void 0 : _c.name }), _jsxs("div", { className: "flex gap-4 pb-4", children: [_jsx("div", { className: "h-14 w-14", children: _jsx("img", { src: (_d = productDict[productKey]) === null || _d === void 0 ? void 0 : _d.image, alt: i }) }), _jsx("div", { children: repLocations[i].map(function (location, index) {
                                                                            return (_jsxs(_Fragment, { children: [index === 0 ? (_jsx("span", { className: "font-bold", children: location })) : (_jsxs("span", { children: [location, index !== repLocations[i].length - 1 && ", "] })), _jsx("br", {})] }));
                                                                        }) })] })] }), _jsx(Button, { component: Link, to: getUrl("FLASK", (_e = productDict[productKey]) === null || _e === void 0 ? void 0 : _e.verifyUrl(settings.public_id)), size: "large", style: {
                                                            backgroundColor: settings.config.clp_color_primary,
                                                        }, className: "flex self-start w-full ", children: _jsx("div", { className: "font-bold", style: { color: getFontColor(settings.config.clp_color_primary) }, children: _jsx(T, { keyName: "public_clp.verify" }) }) })] })] }, i));
                                }) }), (settings.config.cookie_policy_url || settings.config.privacy_policy_url) && (_jsxs("div", { style: textStyle, className: "flex flex-col space-y-2", children: [_jsxs("h4", { className: "m-0", children: [_jsx(T, { keyName: "landing_page.privacy_documentation" }), ":"] }), settings.config.cookie_policy_url && (_jsxs("a", { href: settings.config.cookie_policy_url, style: textStyle, className: "underline", children: [_jsx(T, { keyName: "landing_page.cookie_policy_name" }), " ", companyName] })), settings.config.privacy_policy_url && (_jsxs("a", { href: settings.config.privacy_policy_url, style: textStyle, className: "underline", children: [_jsx(T, { keyName: "landing_page.privacy_policy_name" }), " ", companyName] }))] })), (settings.config.certificate_image_urls.length !== 0 ||
                            settings.certificates.length !== 0) && (_jsxs("div", { style: textStyle, className: "flex flex-col space-y-2", children: [_jsxs("h4", { className: "m-0", children: [_jsx(T, { keyName: "landing_page.other_certificates" }), ":"] }), _jsx("span", { children: _jsx(T, { keyName: "landing_page.other_certificates.disclaimer", params: { company_name: companyName || "" } }) }), settings.certificates.length !== 0 && (_jsxs("div", { className: "w-full", children: [_jsxs("div", { className: "hidden md:grid md:grid-cols-[repeat(5,1fr)_auto] font-bold mb-2", children: [_jsx("div", { children: "Certificate" }), _jsx("div", { children: "Issuer" }), _jsx("div", { children: "Date Issued" }), _jsx("div", { children: "Valid Until" }), _jsx("div", { children: "Website" })] }), settings.certificates.map(function (certificate) {
                                            var _a;
                                            return (_jsxs("div", { className: clsx("px-2 py-1 -mx-2 rounded", settings.config.theme.toLowerCase() === "light"
                                                    ? "md:even:bg-slate-100"
                                                    : ["md:odd:bg-slate-100/10", "md:even:bg-white/30"]), children: [_jsx("div", { className: "mb-1 font-bold md:hidden", children: "Certificate Details:" }), _jsxs("div", { className: "bg-slate-100/50 p-2 rounded-lg md:bg-transparent md:p-0 md:rounded-none md:grid md:grid-cols-[repeat(5,1fr)_auto]", children: [_jsxs("div", { className: "mb-1 md:mb-0", children: [_jsx("span", { className: "font-semibold md:hidden", children: "Certificate: " }), _jsx("span", { className: "break-all", children: certificate.cert })] }), _jsxs("div", { className: "mb-1 md:mb-0", children: [_jsx("span", { className: "font-semibold md:hidden", children: "Issuer: " }), _jsx("span", { className: "break-all", children: certificate.issuer })] }), _jsxs("div", { className: "mb-1 md:mb-0", children: [_jsx("span", { className: "font-semibold md:hidden", children: "Date Issued: " }), _jsx("span", { className: "break-all", children: getBeautyDate(certificate.issued_at) })] }), _jsxs("div", { className: "mb-1 md:mb-0", children: [_jsx("span", { className: "font-semibold md:hidden", children: "Valid Until: " }), _jsx("span", { className: "break-all", children: getBeautyDate(certificate.valid_until) })] }), _jsxs("div", { children: [_jsx("span", { className: "font-semibold md:hidden", children: "Website: " }), ((_a = certificate.url) === null || _a === void 0 ? void 0 : _a.startsWith("http")) ? (_jsx("a", { href: certificate.url, className: "break-all", style: textStyle, children: certificate.url })) : (_jsx("span", { className: "break-all", children: certificate.url }))] })] })] }, certificate.issued_at));
                                        })] })), settings.config.certificate_image_urls && (_jsx("div", { className: "flex flex-wrap gap-2 py-2 ", children: settings.config.certificate_image_urls.map(function (ci) { return (_jsx("img", { width: 200, height: 200, className: "object-contain", src: ci, alt: "certificate" }, ci)); }) }))] })), _jsxs("div", { children: [_jsxs("div", { className: "flex space-x-1", children: [_jsxs("div", { style: textStyle, children: [_jsx(T, { keyName: "public_clp.website" }), ":"] }), _jsx("a", { style: textStyle, className: "underline", href: PRIGHTER_URL, children: PRIGHTER_URL })] }), _jsxs("div", { className: "flex space-x-1", children: [_jsxs("div", { style: textStyle, children: [_jsx(T, { keyName: "public_clp.add_following" }), ":"] }), _jsxs("b", { style: textStyle, children: ["ID-", settings.public_id] })] })] }), _jsxs("div", { className: "flex justify-between ", children: [_jsxs("div", { style: textStyle, children: [_jsx(T, { keyName: "public_clp.powered" }), _jsx("span", { className: "font-bold", children: "Prighter" })] }), _jsxs("div", { className: "flex space-x-4 font-bold", children: [_jsx("a", { style: textStyle, href: PRIVACY_POLICY, children: _jsx(T, { keyName: "public_clp.privacy_policy" }) }), _jsx("a", { style: textStyle, href: LEGAL_INFO, children: _jsx(T, { keyName: "public_clp.legal" }) })] })] })] })] })) : (_jsx(EmptyLandingPage, {})) })) : (_jsx(SkeletonLandingPage, {}));
}
