export function stringifyCompanyAddress(company) {
    var _a = company.address, address = _a === void 0 ? {} : _a;
    var str = address.postal || "";
    if (address.city) {
        str += str ? " ".concat(address.city) : address.city;
    }
    if (address.street) {
        str += str ? ", ".concat(address.street) : address.street;
    }
    if (address.country) {
        str += str ? ", ".concat(address.country.flag) : address.country.flag;
    }
    return str || "No Address";
}
export function stringifyUserAddress(user) {
    var str = user.company_postal_code || "";
    if (user.company_city) {
        str += str ? " ".concat(user.company_city) : user.company_city;
    }
    if (user.company_address) {
        str += str ? ", ".concat(user === null || user === void 0 ? void 0 : user.company_address) : user.company_address;
    }
    if (user.company_country) {
        str += str ? ", ".concat(user.company_country) : user.company_country;
    }
    return str || "No Address";
}
export function stringifyBusinessAddress(address) {
    var str = address.postal || "";
    if (address.city) {
        str += str ? " ".concat(address.city) : address.city;
    }
    if (address.street) {
        str += str ? ", ".concat(address.street) : address.street;
    }
    if (address.country) {
        str += str ? ", ".concat(address.country.flag) : address.country.flag;
    }
    return str || "No Address";
}
