var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BaseSelectField, BaseTextField } from "components";
import AddressForm from "components/complex/AddressForm";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";
import { api } from "services";
import AuthorityContactPointList from "pages/Client/Case/Authority/components/AuthorityContactPointList";
import { handleError } from "services/api/error";
export default function AuthorityContactEdit(_a) {
    var _this = this;
    var _b;
    var contactState = _a.contactState, _c = _a.noCommentField, noCommentField = _c === void 0 ? false : _c, _d = _a.companyTitle, companyTitle = _d === void 0 ? "company" : _d, _e = _a.noIndividual, noIndividual = _e === void 0 ? false : _e;
    var contact = contactState[0], setContact = contactState[1];
    var _f = useState(), permissions = _f[0], setPermissions = _f[1];
    var salutationOptions = { mr: "Mr.", mrs: "Mrs." };
    var getPermissions = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.security.getPermissions("BUSINESS", "WRITE")];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [2 /*return*/];
                case 3:
                    setPermissions(res.data);
                    if (res.data.length === 1) {
                        setContact(function (oldContact) {
                            var _a;
                            return (__assign(__assign({}, oldContact), { business: {
                                    public_id: (_a = res.data[0]) === null || _a === void 0 ? void 0 : _a.target_public_id,
                                } }));
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [setContact]);
    useEffect(function () {
        getPermissions();
    }, [getPermissions]);
    return (_jsxs("div", { className: "grid grid-cols-[20px_1fr_20px] gap-4 items-center", "data-testid": "contact-form", children: [(permissions === null || permissions === void 0 ? void 0 : permissions.length) > 1 && (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: "building", color: PRIGHTER_BLUE }), _jsxs(FormControl, { sx: { width: "100%" }, children: [_jsx(InputLabel, { id: "demo-multiple-name-label", children: tolgee.t({
                                    key: "contacts.select_business",
                                }) }), _jsx(Select, { required: true, label: tolgee.t({
                                    key: "contacts.select_business",
                                }), id: "select", className: "w-full", value: ((_b = contact === null || contact === void 0 ? void 0 : contact.business) === null || _b === void 0 ? void 0 : _b.public_id) || "", onChange: function (event) {
                                    setContact(function (oldContact) {
                                        var _a;
                                        return (__assign(__assign({}, oldContact), { business: {
                                                public_id: (_a = event.target) === null || _a === void 0 ? void 0 : _a.value,
                                            } }));
                                    });
                                }, children: permissions === null || permissions === void 0 ? void 0 : permissions.map(function (i) { return (_jsx(MenuItem, { value: i.target_public_id, children: "".concat(i.target_name, " (").concat(i.target_public_id, ")") }, i.target_name)); }) })] }), _jsx("div", {})] })), !noIndividual && (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: "user", color: PRIGHTER_BLUE }), _jsxs("div", { className: "flex space-x-4", children: [_jsx("div", { className: "w-20", children: _jsx(BaseSelectField, { label: tolgee.t({
                                        key: "contacts.title",
                                    }), options: salutationOptions, defaultValue: "", state: [
                                        contact.salutation || "",
                                        function (option) {
                                            return setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { salutation: option })); });
                                        },
                                    ] }) }), _jsx(BaseTextField, { testid: "name", containerClassName: "w-full", value: contact.name, onChange: function (event) {
                                    return setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { name: event.target.value })); });
                                }, label: tolgee.t({
                                    key: "contacts.name",
                                }) })] }), _jsx("div", {})] })), _jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: "building", color: PRIGHTER_BLUE }), _jsx(BaseTextField, { label: tolgee.t({
                            key: "contacts.".concat(companyTitle),
                        }), containerClassName: "w-full", value: contact.organization, onChange: function (event) {
                            return setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { organization: event.target.value })); });
                        } }), _jsx("div", {})] }), _jsx(AuthorityContactPointList, { contactPoints: contact.contact_points, onChange: function (contactPoints) {
                    return setContact(function (prevContact) { return (__assign(__assign({}, prevContact), { contact_points: contactPoints })); });
                } }), _jsx(AddressForm, { multiple: true, addressIcon: true, contactAddresses: contact.addresses, onChange: function (contactAddresses) {
                    return setContact(function (prevContact) { return (__assign(__assign({}, prevContact), { addresses: contactAddresses })); });
                } }), !noCommentField && (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { className: "self-start", icon: "comment", color: PRIGHTER_BLUE }), _jsx(BaseTextField, { containerClassName: "w-full", multiline: true, value: contact.comment, onChange: function (event) {
                            return setContact(function (oldContact) { return (__assign(__assign({}, oldContact), { comment: event.target.value })); });
                        }, label: tolgee.t({
                            key: "generic.comment",
                        }) }), _jsx("div", {})] }))] }));
}
