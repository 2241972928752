import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { EditableText, SplitButton, VerticalDataValue } from "components";
import { stringifyAddress } from "helpers/general";
import { REACT_BASE_ROUTE } from "const/env";
import { getUrl } from "helpers";
import CompanyAddressModal from "pages/Client/Company/Components/CompanyAddressModal";
import BusinessUpdateModal from "pages/Admin/ManageUser/ManageBusiness/Components/BusinessUpdateModal";
import tolgee from "services/translation";
export default function ManageBusinessHeader(_a) {
    var business = _a.business, handleReload = _a.handleReload;
    var businessId = useParams().businessId;
    var navigate = useNavigate();
    var _b = useState(true), addressModal = _b[0], setAddressModal = _b[1];
    var _c = useState(true), companyNameModal = _c[0], setCompanyNameModal = _c[1];
    var _d = useState(true), companySignModal = _d[0], setCompanySignModal = _d[1];
    var splitItems = [
        {
            name: "Create DSR (EU)",
            icon: "plus",
            action: function () {
                return window.location.assign(getUrl("FLASK", "/dsrtool/manuallyadddsr/".concat(businessId, "/dsr_eu")));
            },
        },
        {
            name: "Create DSR (UK)",
            icon: "plus",
            action: function () {
                return window.location.assign(getUrl("FLASK", "/dsrtool/manuallyadddsr/".concat(businessId, "/dsr_uk")));
            },
        },
        {
            name: "Manage Permissions",
            icon: "plus",
            action: function () { return navigate("/business/".concat(businessId, "/permissions")); },
        },
    ];
    var dangerousSplitItems = [
        {
            name: "Delete This Business",
            icon: "trash",
            action: function () {
                return window.location.assign(getUrl("FLASK", "/administration/business/".concat(businessId, "/delete")));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex space-x-2", children: [_jsx(IconButton, { onClick: function () { return navigate(-1); }, children: _jsx(FontAwesomeIcon, { icon: "chevron-left" }) }), _jsxs("h1", { children: [business.company_name, " (", business.public_id, ")"] })] }), _jsxs("div", { className: "flex flex-row flex-wrap space-x-8 h-min", children: [business.company_name && (_jsx(VerticalDataValue, { title: tolgee.t({ key: "company_name" }), value: _jsx(EditableText, { value: business.company_name, modalState: [companyNameModal, setCompanyNameModal], children: _jsx(BusinessUpdateModal, { business: business, onUpdate: function () {
                                    handleReload();
                                    setCompanyNameModal(true);
                                }, field: "company_name" }) }) })), business.config.slug && _jsx(VerticalDataValue, { title: "Slug", value: business.config.slug }), _jsx(VerticalDataValue, { title: "Public ID", value: business.public_id }), business.company_authorized_to_sign && (_jsx(VerticalDataValue, { title: tolgee.t({ key: "company_authorized_to_sign" }), value: _jsx(EditableText, { value: business.company_authorized_to_sign, modalState: [companySignModal, setCompanySignModal], children: _jsx(BusinessUpdateModal, { business: business, onUpdate: function () {
                                    handleReload();
                                    setCompanySignModal(true);
                                }, field: "company_authorized_to_sign" }) }) })), _jsx(VerticalDataValue, { title: "Landing Page", value: _jsx("a", { href: "/portal/".concat(businessId), children: "".concat(REACT_BASE_ROUTE, "/portal/").concat(businessId) }) }), _jsx(VerticalDataValue, { title: "Address", value: _jsx(EditableText, { value: stringifyAddress(business.address), modalState: [addressModal, setAddressModal], children: _jsx(CompanyAddressModal, { onAddressUpdate: function () {
                                    handleReload();
                                    setAddressModal(true);
                                }, address: business.address }) }) })] }), _jsxs("div", { className: "space-x-4 my-4", children: [_jsx(SplitButton, { items: splitItems, listHeight: "424px", state: [0, function () { }] }), _jsx(SplitButton, { items: dangerousSplitItems, listHeight: "424px", state: [0, function () { }], color: "error" })] })] }));
}
