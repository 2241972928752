var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, FormControlLabel, FormGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import { BaseButton, BaseSelectField } from "components";
import { api } from "services";
import { notify } from "store/app";
import { sanitizeAddresses, sanitizeContactPoints, stringifyContactNameAndCompany, } from "helpers/contact";
import tolgee from "services/translation";
import AuthorityContactEdit from "pages/Client/Case/Authority/components/AuthorityContactEdit";
import { handleError } from "services/api/error";
import { serializeContactDirectory } from "helpers/api";
export default function ChangeSenderModal(_a) {
    var _this = this;
    var onCloseModal = _a.onCloseModal, onReload = _a.onReload, senderState = _a.senderState, caseUuid = _a.caseUuid, businessId = _a.businessId;
    var dispatch = useDispatch();
    var senderData = senderState[0], setSenderData = senderState[1];
    var _b = useState(false), chooseFromContact = _b[0], setChooseFromContact = _b[1];
    var _c = useState(false), hasContact = _c[0], setHasContact = _c[1];
    var _d = useState({}), contactOptions = _d[0], setContactOptions = _d[1];
    var selectedContact = useState("");
    useEffect(function () {
        function getContacts() {
            return __awaiter(this, void 0, void 0, function () {
                var res, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, api.contactDirectory.getContactsOld(businessId)];
                        case 1:
                            res = _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            handleError(e_1);
                            return [2 /*return*/];
                        case 3:
                            res = res.data.map(function (contact) { return serializeContactDirectory(contact); });
                            if (res.length > 0) {
                                setContactOptions(Object.fromEntries(Object.values(res).map(function (contact) { return [
                                    contact.uuid,
                                    stringifyContactNameAndCompany(contact === null || contact === void 0 ? void 0 : contact.name, contact === null || contact === void 0 ? void 0 : contact.organization, contact === null || contact === void 0 ? void 0 : contact.salutation),
                                ]; })));
                                selectedContact[1](res[0].uuid);
                                setHasContact(true);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (Object.keys(contactOptions).length === 0) {
            getContacts();
        }
    }, [businessId, contactOptions, selectedContact]);
    var handleChangeSender = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_2, sanitizedContact, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!chooseFromContact) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.authCase.updateCaseSenderWithContactUuid(caseUuid, selectedContact[0])];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [2 /*return*/];
                case 4: return [3 /*break*/, 9];
                case 5:
                    sanitizedContact = __assign(__assign({}, senderData), { addresses: sanitizeAddresses(senderData.addresses), contact_points: sanitizeContactPoints(senderData.contact_points) });
                    _a.label = 6;
                case 6:
                    _a.trys.push([6, 8, , 9]);
                    return [4 /*yield*/, api.authCase.updateCaseSender(caseUuid, sanitizedContact)];
                case 7:
                    _a.sent();
                    return [3 /*break*/, 9];
                case 8:
                    e_3 = _a.sent();
                    handleError(e_3);
                    return [2 /*return*/];
                case 9:
                    dispatch(notify({
                        message: tolgee.t({
                            key: "cases.authority_changed",
                        }),
                        type: "SUCCESS",
                    }));
                    onCloseModal();
                    onReload();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col w-[66rem] space-y-4", children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("h2", { className: "mt-0", children: _jsx(T, { keyName: "cases.edit_authority" }) }), hasContact && (_jsx(FormGroup, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: chooseFromContact, onChange: function (event) { return setChooseFromContact(event.target.checked); } }), label: tolgee.t({
                                key: "contacts.copy_existing_contacts",
                            }) }) }))] }), chooseFromContact ? (_jsx(BaseSelectField, { state: selectedContact, options: contactOptions })) : (_jsx("div", { className: "px-16", children: _jsx(AuthorityContactEdit, { contactState: [senderData, setSenderData], companyTitle: "authority", noCommentField: true, noIndividual: true }) })), _jsxs("div", { className: "self-end space-x-4", children: [_jsx(BaseButton, { onClick: function () { return onCloseModal(); }, children: _jsx(T, { keyName: "generic.cancel" }) }), _jsx(BaseButton, { disabled: chooseFromContact && selectedContact[0].length === 0, onClick: function () { return handleChangeSender(); }, children: chooseFromContact
                            ? tolgee.t({
                                key: "contacts.copy_contact",
                            })
                            : tolgee.t({
                                key: "contacts.update",
                            }) })] })] }));
}
